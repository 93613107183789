<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

      <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
          <div class="font-22-500" style="width:100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
              Lo studio
          </div>
          <div class="font-22-500 color-primary" style="width:100%; margin-top:20px; text-align: center;">
            Un team di professionisti dinamico, preparato e sempre attento alle esigenze del cliente.<br>
            La scelta smart per la tua consulenza.
          </div>
          <div style="margin-top:40px;">
              <img src="./assets/img/home_slider_03.jpg" width="100%">
          </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
          <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start" style="padding-left: 20px; padding-right: 20px; height: 30vw;">
            <div class="font-40-300 color-primary" style="line-height: 35px">
              <span style="font-weight: 900;">La forza della sinergia</span> 
            </div>
            <div class="font-22-300 color-grigio" style="margin-top:20px;">
              ActioAvvocati è un progetto che nasce dalla convinzione che la sinergia è una risorsa e che la condivisione delle esperienze professionali è un valore aggiunto nell’esercizio dell’attività forense.
            </div>
          </div>
          <div fxFlex="50" style="background-image: url('./assets/img/new/03_SINERGIA_lo_studio.jpeg'); height: 30vw; background-size:cover; background-position:center;"> 
              
          </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px;">
        <div fxFlex="50" style="background-image: url('./assets/img/lostudio02_essenza_della_professione.jpg'); height: 30vw; background-size:cover; background-position:center;"> 
              
        </div>
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start" style="padding-left: 60px; padding-right: 20px; height: 30vw;">
          <div class="font-40-300 color-primary" style="line-height: 35px">
            <span style="font-weight: 900;">L’essenza della professione</span> 
          </div>
          <div class="font-22-300 color-grigio" style="margin-top:20px;">
            Essere avvocati ascoltando e interpretando le esigenze del cliente, mettendo al primo posto la sua utilità, fornendo una consulenza altamente specializzata e costantemente aggiornata anche in settori giuridici emergenti o di nicchia.
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px;">
        <div fxFlex="50" fxLayout="column" fxLayoutAlign="center start" style="padding-left: 20px; padding-right: 20px; height: 30vw;">
          <div class="font-40-300 color-primary" style="line-height: 35px">
            <span style="font-weight: 900;">Gli obiettivi</span> 
          </div>
          <div class="font-22-300 color-grigio" style="margin-top:20px;">
            Uno studio legale radicato nella tradizione ma proiettato verso una moderna concezione della professione che ha scelto di avere base nel territorio d’origine senza però limitare i suoi orizzonti geografici grazie ad una concezione dinamica del lavoro, all’utilizzo della telematica giuridica e alla creazione di reti di collaborazione.
          </div>
        </div>
        <div fxFlex="50" style="background-image: url('./assets/img/lostudio_03_obiettivi.jpg'); height: 30vw; background-size:cover; background-position:center;"> 
              
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top:80px; background-color: #003664; padding-left: 50px; padding-right:50px; padding-top:20px; padding-bottom:20px;">
          <div class="font-22-300 color-white">
            Per qualsiasi informazione <span style="font-weight:500;">scrivici un messaggio</span> attraverso il form di contatto.  
          </div>
          <div>
            <button (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
          </div>
      </div>

      
    <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; padding-top:80px; padding-left:50px; padding-right:50px;">
        <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
        <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
          Chi siamo
        </div>
        <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
      </div>
  
      <div fxLayout="row" fxLayoutAlign="start start" style="background-color: #F7F7F7; padding-left: 50px; padding-right:50px; padding-top:60px; padding-bottom:60px;">
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_01_parisi.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Nicola Parisi
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              parisi@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_nicola_parisi')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_02_dalessandro.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Vito D’Alessandro
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              dalessandro@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_vito_d_alessandro')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_03_morelli.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Annalisa Morelli
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
            morelli@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_annalisa_morelli')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_04_positano.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Roberto Positano
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              positano@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_roberto_positano')" class="button-border">About me</button>
          </div>
        </div>
    </div>

    <app-footer></app-footer>
  </div>
</section>



<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px;">
        <div class="font-22-500" style="width:100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
            Lo studio
        </div>
        <div class="font-20-500 color-primary" style="width:100%; line-height: 20px; margin-top:20px; text-align: center;">
          Un team di professionisti dinamico, preparato e sempre attento alle esigenze del cliente.<br>
          La scelta smart per la tua consulenza.
        </div>
    </div>

    <div style="margin-top:40px; width: 100%;">
        <img src="./assets/img/home_slider_03.jpg" width="100%">
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px;">
        <div class="font-40-300 color-primary" style="line-height: 35px">
          <span style="font-weight: 900;">La forza della sinergia</span> 
        </div>
        <div class="font-22-300 color-grigio" style="margin-top:20px;">
          ActioAvvocati è un progetto che nasce dalla convinzione che la sinergia è una risorsa e che la condivisione delle esperienze professionali è un valore aggiunto nell’esercizio dell’attività forense.
        </div>
        <div style="background-image: url('./assets/img/new/03_SINERGIA_lo_studio.jpeg'); margin-top: 20px; width: 100%; height: 50vw; background-size:cover; background-position:center;"> 
            
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top: 40px;">
      <div class="font-40-300 color-primary" style="line-height: 35px">
        <span style="font-weight: 900;">L’essenza della professione</span> 
      </div>
      <div class="font-22-300 color-grigio" style="margin-top:20px;">
        Essere avvocati ascoltando e interpretando le esigenze del cliente, mettendo al primo posto la sua utilità, fornendo una consulenza altamente specializzata e costantemente aggiornata anche in settori giuridici emergenti o di nicchia.
      </div>
      <div style="background-image: url('./assets/img/lostudio02_essenza_della_professione.jpg'); margin-top: 20px; width: 100%; height: 50vw; background-size:cover; background-position:center;"> 
            
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top: 40px;">
      <div class="font-40-300 color-primary" style="line-height: 35px">
        <span style="font-weight: 900;">Gli obiettivi</span> 
      </div>
      <div class="font-22-300 color-grigio" style="margin-top:20px;">
        Uno studio legale radicato nella tradizione ma proiettato verso una moderna concezione della professione che ha scelto di avere base nel territorio d’origine senza però limitare i suoi orizzonti geografici grazie ad una concezione dinamica del lavoro, all’utilizzo della telematica giuridica e alla creazione di reti di collaborazione.
      </div>
      <div style="background-image: url('./assets/img/lostudio_03_obiettivi.jpg'); margin-top: 20px; width: 100%; height: 50vw; background-size:cover; background-position:center;"> 
            
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:15px; padding-right:15px; margin-top:40px;">
      <div class="font-22-300 color-white" style="text-align: center;">
        Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
      </div>
      <button style="margin-top: 20px;" (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
    </div>  

    <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; padding-top:40px;">
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="50" style="text-align: center;" class="font-30-900 color-primary">
        Chi siamo
      </div>
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>

    <div style="padding-top: 40px; padding-bottom: 20px; background-color: #F7F7F7;">
      <swiper class="swiper-container" [config]="configChisiamo" fxFlex="auto">
        <div *ngFor="let item of chisiamoArray" class="swiper-slide">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div [style.backgroundImage]="'url('+ item.path +')'" style="background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              {{item.name}}
            </div>
            <div class="font-15-300 color-grigio">
                {{item.email}}
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink(item.link)" class="button-border">About me</button>
            </div>
          </div>
        </div>
      </swiper>
    </div>
    

  <app-footer></app-footer>
</div>
</section>