import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goComposing(){
    window.open('https://www.composingstudio.com');
  }

  gotoFacebook(){
    window.open('https://www.facebook.com/actioavvocati/');
  }

  gotoLinkedin(){
    window.open('https://www.linkedin.com/company/actioavvocati/');
  }
}
