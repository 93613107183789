import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth'
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as firebase from 'firebase';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit {

  public menuIndex = 'news';
  public user;
  public showSpinnerLoad = false;

  public newsForm: FormGroup;
  public message;
  public docImg = [
   
  ];
  
  constructor(private router: Router, public formBuilder: FormBuilder, public afAuth: AngularFireAuth, private storage: AngularFireStorage) {
    this.newsForm = formBuilder.group({
      titolo: new FormControl('', Validators.compose([Validators.required])),
      testo: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit() {
    var self = this;
    this.afAuth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        self.user = user;
      }
    });
  }

  public percentual;
  /* CARICO UNA SINGOLA FOTO */
  onFileChanged(event) {
    var selectedFile = event.target.files;
    console.log(selectedFile.length);
    for(let i=0; i<selectedFile.length; i++){
      this.uploadPhoto(selectedFile[i]);
    }
  }

  private uploadPhoto(selectedFile): void {
    var self = this;
    self.showSpinnerLoad=true; 
    var nameimg = this.generateUUID();
    
    const file = selectedFile;
    const filePath = 'news/'+nameimg+'.jpg';
    const fileRef = self.storage.ref(filePath);
    const task = self.storage.upload(filePath, file);

    // observe percentage changes
    let uploadPercent = task.percentageChanges().subscribe(data => { 
      console.log(data);
      this.percentual = data;
    });

    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        let uploadSubscribe = fileRef.getDownloadURL().subscribe(data=>{
          var docImg = {
            name_image: nameimg+'.jpg',
            url_image : data,
          } 
          self.docImg.push(docImg);
          self.showSpinnerLoad=false;  
          console.log(self.docImg);
        });
      }) 
    ).subscribe();
  }

  /* ELIMINAZIONE SINGOLA IMMAGINE */
  removePhoto(index): void {
    var name_image = this.docImg[index].name_image;
    var storageRef = this.storage.ref('news/');
    var desertRef = storageRef.child(name_image);    
    desertRef.delete();
    this.docImg.splice(index, 1);
  }
    
  private generateUUID(): any {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  create(){
    this.message=null;
    if(!this.newsForm.valid){
      console.log(this.newsForm.valid);
      this.message = 'Inserisci il titolo e un testo.';
    }else{
      console.log(this.newsForm.value);
      var docData = {
        titolo: this.newsForm.value.titolo,
        testo: this.newsForm.value.testo,
        image: this.docImg,
        created_at: Date.now(),
      } 

      var key = firebase.database().ref('/news/').push(docData).key;
      console.log('News insetia :'+key);
      this.router.navigate(['/news']);
    }
  }
}
