<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
      <div *ngIf="newsDetail" style="padding-right: 50px; padding-left: 50px; margin-top: 60px;">
        <div class="font-40-300 color-primary" style="line-height: 35px">
            <span style="font-weight: 900;">{{newsDetail.titolo}}</span>
        </div>
        <div class="font-22-300 color-primary" style="line-height: 22px; margin-top: 5px; margin-bottom: 20px; margin-top: 10px;">
            <span>{{newsDetail.created_at | date: 'dd/MM/yyyy'}}</span>
        </div>
        <div *ngIf="newsDetail.image">
            <div [style.backgroundImage]="'url('+ newsDetail.image[0].url_image +')'" style="background-size:cover; background-position:0% 20%; width: 100%; height: 50vw;">
                
            </div>
        </div>
        <div *ngIf="!newsDetail.image">
            <div style="background-color: gainsboro; width: 100%; height: 50vw;">
                
            </div>
        </div>
        <div class="font-22-300 color-grigio" style="margin-top:20px;" [innerHtml]="textFormat(newsDetail.testo)"></div>
      </div>
  
      <app-footer></app-footer>
    </div>
  </section>