<section fxHide fxShow.md fxShow.gt-md>
  <div fxLayout="row" fxLayoutAlign="center center" style="padding-top:110px; padding-bottom:60px;">
    <img src="./assets/img/logo/logo.svg" width="170px">
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; background-color:#003664; padding-left:50px; padding-right:50px; padding-top:25px; padding-bottom:25px;">
    <div fxFlex="33">
      <div class="font-15-300 color-white" style="margin-top:20px;">
          Via Siderno, 10 - 00178 Roma<br>
          Via Luzzatti, 15 - 70125 Bari<br>
          Via Carducci, 46 - 70016 Noicàttaro (Ba)<br>
          info@actioavvocati.it
          <br><br>
          <div style="cursor: pointer; font-weight: 500; outline: none;" routerLink="/login">Pannello Admin</div>
      </div>
    </div>
    <div fxFlex="34" fxLayout="column" fxLayoutAlign="center center" >
      <div routerLink="/cookie-policy"  style="cursor: pointer; color:#cfcfcf; font-weight: 500;">
        Privacy - Cookies
      </div>
    </div>
    <div fxFlex="33" fxLayout="column" fxLayoutAlign="center end">
      <div>
          <i (click)="gotoFacebook()" style="cursor: pointer; color:white; font-size: 25px; margin-right: 10px;" class="fab fa-facebook-square"></i>
          <i (click)="gotoLinkedin()" style="cursor: pointer; color:white; font-size: 25px;" class="fab fa-linkedin-in"></i>
      </div>
      <div class="font-13-300 color-white">actioavvocati.it ©2020 - All right reserved - v0.4</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" style="background-color: #2B2B2B; height: 60px; padding-right:50px;">
    <div class="font-13-300 color-white" style="cursor: pointer;">
      a custom project of <span (click)="goComposing()" style="font-weight: 600; font-size: 15px;">composingstudio</span>
    </div>
  </div>
</section>


<section fxHide fxShow.lt-md>
  <div fxLayout="row" fxLayoutAlign="center center" style="padding-top:110px; padding-bottom:60px;">
    <img src="./assets/img/logo/logo.svg" width="170px">
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" style="width: 100%; background-color:#003664; padding-left:50px; padding-right:50px; padding-top:25px; padding-bottom:25px;">

    <div class="font-15-300 color-white" style="margin-top:20px; text-align: center;">
        Via Siderno, 10 - 00178 Roma<br>
        Via Luzzatti, 15 - 70125 Bari<br>
        Via Carducci, 46 - 70016 Noicàttaro (Ba)<br>
        info@actioavvocati.it
        <br><br>
        <div routerLink="/login">Pannello Admin</div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 20px;">
      <div>
          <i (click)="gotoFacebook()" style="cursor: pointer; color:white; font-size: 25px; margin-right: 10px;" class="fab fa-facebook-square"></i>
          <i (click)="gotoLinkedin()" style="cursor: pointer; color:white; font-size: 25px;" class="fab fa-linkedin-in"></i>
      </div>
      
      <div routerLink="/cookie-policy" style="color:#cfcfcf; font-weight: 500; margin-top: 20px; cursor: pointer;" >
        Privacy - Cookies
      </div>
   
      <div class="font-13-300 color-white" style="margin-top: 10px;">actioavvocati.it ©2020 - All right reserved - v0.4</div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" style="background-color: #2B2B2B; height: 60px;">
    <div class="font-13-300 color-white" style="cursor: pointer; text-align: center;">
      a custom project of <span (click)="goComposing()" style="font-weight: 600; font-size: 15px;">composingstudio</span>
    </div>
  </div>
</section>