<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px; margin-bottom: 60px;">
        <div fxFlex="30">
          
        </div>
        <div fxFlex="40" *ngIf="type==0">
          <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Accedi
          </div>
          <div style="margin-top:40px;">
              <form autocomplete="nope" fxLayout="column" fxLayoutAlign="center start" [formGroup]="loginForm" (ngSubmit)="accedi()" style="width:100%;">
          
                  <div class="font-22-500 color-primary">
                    Inserisci la tua email e password
                  </div>
    
                  <div style="margin-top:40px; width:100%;">
                    <input autocomplete="nope" class="input-actio" placeholder="Email" formControlName="email">
                  </div>
          
                  <div style="margin-top:20px; width:100%;">
                    <input autocomplete="nope" class="input-actio" placeholder="Password" type="password" formControlName="password">
                  </div>
    
                  <div *ngIf="message" class="font-16-500 color-black" style="padding-top:20px;">
                    {{message}}
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:20px; width: 100%;">
                    <button fxLayout="row" fxLayoutAlign="center center" class="button-border" type="submit" style="width:100% !important;">
                        <div *ngIf="!spinnerLogin">Login</div>
                        <div *ngIf="spinnerLogin"><mat-spinner [diameter]="20"></mat-spinner></div>
                    </button>
                  </div>
              </form>
              <!--
              <div (click)="type=1" style="cursor: pointer; font-weight: 500; font-size: 14px; margin-top: 20px;">Registra un nuovo utente</div>
              -->
          </div>
        </div>
        <div fxFlex="40" *ngIf="type==1">
            <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
                Registrati
            </div>
            <div style="margin-top:40px;">
                <form autocomplete="nope" fxLayout="column" fxLayoutAlign="center start" [formGroup]="registerForm" (ngSubmit)="create()" style="width:100%;">
            
                    <div class="font-22-500 color-primary">
                      Inserisci la tua email e password
                    </div>
      
                    <div style="margin-top:20px; width:100%;">
                        <input autocomplete="nope" class="input-actio" placeholder="Nome" formControlName="name">
                    </div>

                    <div style="margin-top:20px; width:100%;">
                        <input autocomplete="nope" class="input-actio" placeholder="Cognome" formControlName="surname">
                    </div>

                    <div style="margin-top:20px; width:100%;">
                      <input autocomplete="nope" class="input-actio" placeholder="Email" formControlName="email">
                    </div>
            
                    <div style="margin-top:20px; width:100%;">
                      <input autocomplete="nope" class="input-actio" placeholder="Password" type="password" formControlName="password">
                    </div>
      
                    <div *ngIf="message" class="font-16-500 color-black" style="padding-top:20px;">
                      {{message}}
                    </div>
      
                    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:20px; width: 100%;">
                      <button fxLayout="row" fxLayoutAlign="center center" class="button-border" type="submit" style="width:100% !important;">
                          <div *ngIf="!spinnerLogin">Registra</div>
                          <div *ngIf="spinnerLogin"><mat-spinner [diameter]="20"></mat-spinner></div>
                      </button>
                    </div>
                </form>
            </div>
          </div>
        <div fxFlex="30">
          
        </div>
      </div>

      <app-footer></app-footer>
    </div>
</section>

