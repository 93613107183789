import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/auth'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public menuIndex = 'home';
  public contactForm: FormGroup;
  public spinnerContact;
  public message;

  //mappa
  public mappa = false;
  public zoom = 7;
  public latMap = 41.5012595;
  public lonMap = 15.0696864;
  public coords = [];

  public user;

  constructor(public afAuth: AngularFireAuth, public formBuilder: FormBuilder, private db: AngularFireDatabase, public snackBar: MatSnackBar) { 
    this.contactForm = formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      message: new FormControl('', Validators.compose([Validators.required])),
      condizione: new FormControl(false),
    });

    this.addcoords();
  }

  ngOnInit() {
    var self = this;
    this.afAuth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        self.user = user;
      }
    });
  }

  contactSend(){
    var self = this;
    if(this.contactForm.valid){
      if(this.contactForm.value.condizione){
        var docData = {
          name: this.contactForm.value.name,
          email: this.contactForm.value.email,
          message: this.contactForm.value.message,
        };

        self.message = null;

        console.log(docData);
        this.db.database.ref('/contact').push(docData).then(c=>{
          console.log(c);
        });
        
        let snackBarRef = self.snackBar.open('Messaggio inviato! Ti contatteremo al più presto.', 'OK');

      }else{
        this.message = 'Accetta il trattamento dei dati personali.';
      }
    }else{
      this.message = 'Compila tutti i campi.';
    }
  }

  gotoFacebook(){
    window.open('https://www.facebook.com/actioavvocati/');
  }

  gotoLinkedin(){
    window.open('https://www.linkedin.com/company/actioavvocati/');
  }

  addcoords(){

    var doc = {
      id: 1,
      zIndex : 1,
      lat: 41.03659,
      lng: 16.9880925,
      iconUrl : {
        url: 'assets/img/marker_map.svg',
        scaledSize: {
          width: 40,
          height: 40,
        },
      }
    }

    this.coords.push(doc);

    var doc = {
      id: 1,
      zIndex : 1,
      lat: 41.1001646,
      lng: 16.8740505,
      iconUrl : {
        url: 'assets/img/marker_map.svg',
        scaledSize: {
          width: 40,
          height: 40,
        },
      }
    }

    this.coords.push(doc);

    var doc = {
      id: 1,
      zIndex : 1,
      lat: 41.836795,
      lng: 12.5535157,
      iconUrl : {
        url: 'assets/img/marker_map.svg',
        scaledSize: {
          width: 40,
          height: 40,
        },
      }
    }

    this.coords.push(doc);
  }
}
