import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// FLEX LAYOUT
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// MATERIAL
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule  } from '@angular/material/form-field'
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';

//ANGULAR
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient }     from '@angular/common/http';


//MODULES
import { HeaderComponent } from './modules/header/header.component';
import { FooterComponent } from './modules/footer/footer.component';

//PAGES
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { StudyComponent } from './pages/study/study.component';
import { ServiceComponent } from './pages/service/service.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { CookieComponent } from './pages/cookie/cookie.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsCreateComponent } from './pages/news-create/news-create.component';
import { NewsUpdateComponent } from './pages/news-update/news-update.component';

//SERVICES
import { AuthService } from './service/auth/auth.service';

import { OwlModule } from 'ngx-owl-carousel';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { AgmCoreModule } from '@agm/core';
import { ContactListComponent } from './pages/contact-list/contact-list.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

var firebaseConfig = {
  apiKey: "AIzaSyAdZ7Bs6WAVfVVXxfmUK2WwXQxcCdwmNhA",
  authDomain: "actio-9e80e.firebaseapp.com",
  databaseURL: "https://actio-9e80e.firebaseio.com",
  projectId: "actio-9e80e",
  storageBucket: "actio-9e80e.appspot.com",
  messagingSenderId: "319836593140",
  appId: "1:319836593140:web:5572e8c6d4e52e2d3777a1",
  measurementId: "G-4HQR7E6N2E"
};

@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    AboutComponent,
    StudyComponent,
    ServiceComponent,
    NewsDetailComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CookieComponent,
    LoginComponent,
    NewsComponent,
    NewsCreateComponent,
    NewsUpdateComponent,
    ContactListComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSliderModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireDatabaseModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    OwlModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    SwiperModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXeO6OZez8_J95_DptlEwrCEpuMgrd3R8'
   })
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
