import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public menuIndex = 'home';
  public imgGallery = [
    {
      path: './assets/img/home_slider_01.jpg',
    },
    {
      path: './assets/img/new/01_home_page_seconda_foto.jpg',
    },
    {
      path: './assets/img/home_slider_04.jpg',
    },
    {
      path: './assets/img/new/02_home_page_quarta_foto.jpg',
    },
  ];


  public chisiamoArray = [
    {
      name: 'Avv. Nicola Parisi',
      path: './assets/img/chisiamo_01_parisi.jpg',
      link: '/about/avv_nicola_parisi',
      email: 'parisi@actioavvocati.it'
    },
    {
      name: ' Avv. Vito D’Alessandro',
      path: './assets/img/chisiamo_02_dalessandro.jpg',
      link: '/about/avv_vito_d_alessandro',
      email: 'dalessandro@actioavvocati.it'
    },
    {
      name: 'Avv. Annalisa Morelli',
      path: './assets/img/chisiamo_03_morelli.jpg',
      link: '/about/avv_annalisa_morelli',
      email: 'morelli@actioavvocati.it'
    },
    {
      name: 'Avv. Roberto Positano',
      path: './assets/img/chisiamo_04_positano.jpg',
      link: '/about/avv_roberto_positano',
      email: 'positano@actioavvocati.it'
    },
  ];

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: true,
    autoplay: true,
  };

  public configChisiamo: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    autoplay: true,
  };

  constructor(private router: Router) { 

  }

  ngOnInit() {
  }

  gotoLink(link){
    this.router.navigate([link]);
  }
}
