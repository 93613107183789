<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
        <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px; margin-bottom: 60px;">
            
            <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
                Privacy e Cookie Policy del presente sito web
            </div>
            
            <div>
                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Cos’è una Privacy e Cookie Policy</span>
                    <br>E’ il documento che descrive le modalità di trattamento dei dati personali degli utenti che visitano il sito Internet www.actioavvocati.it. Trattasi, pertanto, di una informativa resa anche ai sensi dell’art. 13 e 14 del <span style="font-weight: 500;">Regolamento (UE) 2016/679</span> – Regolamento generale sulla protezione dei dati (“GDPR”) – per chi semplicemente naviga il sito e per gli utenti che inviano spontaneamente comunicazioni ai recapiti di contatto resi pubblici nel sito.
                    <br>Informative di dettaglio possono essere rese in pagine di www.actioavvocati.it in relazione a specifici servizi).
                    <br>L’informativa non è valida per altri siti web eventualmente consultabili tramite i nostri link, di cui actioavvocati.it non è in alcun modo responsabile.
                </div>

                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Titolare del trattamento</span>
                    <br>Titolare del trattamento dei dati personali è l’Avv. Nicola Parisi con studio in Noicàttaro (BA) alla Via Carducci 46 – 70016 Noicàttaro (BA).
                    <br>Luogo di trattamento dei dati – Soggetti che possono trattarli o accedervi – Destinatari
                    <br>I trattamenti connessi ai servizi web offerti da questo sito (fisicamente posto in hosting sul territorio italiano presso Aruba) sono effettuati presso la sede del Titolare del trattamento e sono curati solo da personale autorizzato al loro trattamento. Occasionalmente, potrà essere consentito a terzi fornitori l’accesso temporaneo alle memorie del sito per esclusivi motivi di assistenza tecnica, nel rispetto delle prescrizioni di legge e sotto la sorveglianza del Titolare. I dati non sono comunicati all’estero.
                    <br>Nessun dato derivante dall’interazione con il sito viene comunicato a terzi o diffuso.
                </div>

                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Tipologia di dati trattati<br>Dati di navigazione</span>
                    <br>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
                    <br>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
                    <br>Saranno raccolti i dati di navigazione esclusivamente nell’interesse legittimo di permettere all’utente la fruizione dei contenuti pubblicati sui siti Internet del Titolare ed una loro corretta amministrazione e gestione. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati in questione potrebbero essere utilizzati per l’accertamento di responsabilità in caso di eventuali reati informatici ai danni del nostro sito: salva questa eventualità, in genere i dati sui contatti web non vengono conservati per più di sette giorni.
                </div>

                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Cookies</span>
                    <br>I cookies sono piccoli file di testo che i siti visitati inviano al terminale dell’Utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
                    <br>In conformità con quanto disposto dal Provvedimento generale del Garante per la Protezione dei Dati Personali dell’8 maggio 2014, si informano gli utenti sulle modalità di gestione dei cookie del presente sito.
                    <br>Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, nè vengono utilizzati c.d. cookies di sessione o cookies persistenti di alcun tipo, e tantomeno cookie di profilazione. Il Content Management System utilizzato da actioavvocati.it potrebbe rilasciare cookie tecnici indispensabili alla corretta visualizzazione o navigazione del sito.
                    <br>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi con estensione @actioavvocati.it indicati su questo sito comporta per sua stessa natura la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Le basi giuridiche del trattamento sono, pertanto, il legittimo interesse del Titolare di rispondere alle comunicazioni pervenute o la necessità di evadere richieste precontrattuali avanzate dall’interessato. I dati saranno conservati per il tempo necessario a soddisfare eventuali richieste del mittente o questioni ivi sottoposte al Titolare e, comunque, per il tempo imposto da specifiche disposizioni di legge. Il mittente ha comunque il diritto di chiedere la cancellazione degli stessi secondo le modalità, condizioni e limiti previsti dall’art. 17 del GDPR.
                    <br>Invitiamo i nostri utenti a non inviare nelle loro comunicazioni nominativi o altri dati personali di soggetti terzi che non siano strettamente necessari.
                    <br>Facoltatività del conferimento dei dati
                    <br>A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire spontaneamente dati personali per richiedere informazioni o servizi. Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.
                </div>
                
                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Modalità del trattamento</span>
                    <br>I dati personali sono trattati in formato elettronico, per il tempo necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
                </div>

                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Diritti degli interessati</span>
                    <br>Ai sensi degli articoli 15 e seguenti del GDPR, l’utente ha il diritto di chiedere in qualunque momento, l’accesso ai suoi dati personali, la rettifica o la cancellazione degli stessi, la limitazione del trattamento nei casi previsti dall’art. 18 del Regolamento, ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che lo riguardano, nei casi previsti dall’art. 20 del GDPR. In ogni momento, l’utente può revocare ex art. 7 del GDPR il consenso prestato (laddove il consenso sia stato richiesto); proporre reclamo all’autorità di controllo competente ex art. 77 del GDPR (Garante per la Protezione dei Dati Personali), qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore.
                    <br>L’utente può, inoltre, formulare una richiesta di opposizione al trattamento dei suoi dati personali ex art. 21 del GDPR nella quale dare evidenza delle ragioni che giustifichino l’opposizione: il Titolare si riserva di valutare l’istanza, che potrebbe non essere accettata laddove sussistano obblighi o motivi legittimi prevalenti sugli interessi, diritti e libertà dell’utente e che impongano o giustifichino la prosecuzione del trattamento.
                    <br>Le richieste inerenti l’esercizio dei summenzionati diritti devono essere rivolte all’attenzione del Titolare del sito actioavvocati.it con sede in Via Carducci 46 – 70016 Noicàttaro (BA) oppure all’indirizzo e-mail: info@actioavvocati.it
                </div>

                <div style="margin-top:40px;">
                    <span style="font-weight: 600; font-size: 19px;">Aggiornamenti della presente Privacy e Cookie Policy</span>
                    <br>Le notizie qui rese possono essere soggette a revisione in seguito a:
                    <br>modifiche della normativa di privacy, per gli aspetti qui di interesse;
                    <br>implementazioni tecnologiche del sito che impattino sulle attuali modalità di trattamento;
                    <br>variazioni organizzative nella struttura di privacy del Titolare che possano interessare l’utente.
                    <br>Gli utenti sono cortesemente invitati a visitare periodicamente la presente Policy di modo da essere costantemente aggiornati sulle caratteristiche del trattamento.
                </div>
            </div>
        </div>
    

      <app-footer></app-footer>
    </div>
</section>


<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px; margin-bottom: 40px;">
        <div class="font-22-500" style="width: 100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
            Privacy e Cookie Policy del presente sito web
        </div>
        <div>
            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Cos’è una Privacy e Cookie Policy</span>
                <br>E’ il documento che descrive le modalità di trattamento dei dati personali degli utenti che visitano il sito Internet www.actioavvocati.it. Trattasi, pertanto, di una informativa resa anche ai sensi dell’art. 13 e 14 del <span style="font-weight: 500;">Regolamento (UE) 2016/679</span> – Regolamento generale sulla protezione dei dati (“GDPR”) – per chi semplicemente naviga il sito e per gli utenti che inviano spontaneamente comunicazioni ai recapiti di contatto resi pubblici nel sito.
                <br>Informative di dettaglio possono essere rese in pagine di www.actioavvocati.it in relazione a specifici servizi).
                <br>L’informativa non è valida per altri siti web eventualmente consultabili tramite i nostri link, di cui actioavvocati.it non è in alcun modo responsabile.
            </div>

            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Titolare del trattamento</span>
                <br>Titolare del trattamento dei dati personali è l’Avv. Nicola Parisi con studio in Noicàttaro (BA) alla Via Carducci 46 – 70016 Noicàttaro (BA).
                <br>Luogo di trattamento dei dati – Soggetti che possono trattarli o accedervi – Destinatari
                <br>I trattamenti connessi ai servizi web offerti da questo sito (fisicamente posto in hosting sul territorio italiano presso Aruba) sono effettuati presso la sede del Titolare del trattamento e sono curati solo da personale autorizzato al loro trattamento. Occasionalmente, potrà essere consentito a terzi fornitori l’accesso temporaneo alle memorie del sito per esclusivi motivi di assistenza tecnica, nel rispetto delle prescrizioni di legge e sotto la sorveglianza del Titolare. I dati non sono comunicati all’estero.
                <br>Nessun dato derivante dall’interazione con il sito viene comunicato a terzi o diffuso.
            </div>

            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Tipologia di dati trattati<br>Dati di navigazione</span>
                <br>I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti.
                <br>In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
                <br>Saranno raccolti i dati di navigazione esclusivamente nell’interesse legittimo di permettere all’utente la fruizione dei contenuti pubblicati sui siti Internet del Titolare ed una loro corretta amministrazione e gestione. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. I dati in questione potrebbero essere utilizzati per l’accertamento di responsabilità in caso di eventuali reati informatici ai danni del nostro sito: salva questa eventualità, in genere i dati sui contatti web non vengono conservati per più di sette giorni.
            </div>

            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Cookies</span>
                <br>I cookies sono piccoli file di testo che i siti visitati inviano al terminale dell’Utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
                <br>In conformità con quanto disposto dal Provvedimento generale del Garante per la Protezione dei Dati Personali dell’8 maggio 2014, si informano gli utenti sulle modalità di gestione dei cookie del presente sito.
                <br>Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, nè vengono utilizzati c.d. cookies di sessione o cookies persistenti di alcun tipo, e tantomeno cookie di profilazione. Il Content Management System utilizzato da actioavvocati.it potrebbe rilasciare cookie tecnici indispensabili alla corretta visualizzazione o navigazione del sito.
                <br>L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi con estensione @actioavvocati.it indicati su questo sito comporta per sua stessa natura la successiva acquisizione dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Le basi giuridiche del trattamento sono, pertanto, il legittimo interesse del Titolare di rispondere alle comunicazioni pervenute o la necessità di evadere richieste precontrattuali avanzate dall’interessato. I dati saranno conservati per il tempo necessario a soddisfare eventuali richieste del mittente o questioni ivi sottoposte al Titolare e, comunque, per il tempo imposto da specifiche disposizioni di legge. Il mittente ha comunque il diritto di chiedere la cancellazione degli stessi secondo le modalità, condizioni e limiti previsti dall’art. 17 del GDPR.
                <br>Invitiamo i nostri utenti a non inviare nelle loro comunicazioni nominativi o altri dati personali di soggetti terzi che non siano strettamente necessari.
                <br>Facoltatività del conferimento dei dati
                <br>A parte quanto specificato per i dati di navigazione, l’utente è libero di fornire spontaneamente dati personali per richiedere informazioni o servizi. Il loro mancato conferimento può comportare l’impossibilità di ottenere quanto richiesto.
            </div>
            
            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Modalità del trattamento</span>
                <br>I dati personali sono trattati in formato elettronico, per il tempo necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
            </div>

            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Diritti degli interessati</span>
                <br>Ai sensi degli articoli 15 e seguenti del GDPR, l’utente ha il diritto di chiedere in qualunque momento, l’accesso ai suoi dati personali, la rettifica o la cancellazione degli stessi, la limitazione del trattamento nei casi previsti dall’art. 18 del Regolamento, ottenere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati che lo riguardano, nei casi previsti dall’art. 20 del GDPR. In ogni momento, l’utente può revocare ex art. 7 del GDPR il consenso prestato (laddove il consenso sia stato richiesto); proporre reclamo all’autorità di controllo competente ex art. 77 del GDPR (Garante per la Protezione dei Dati Personali), qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore.
                <br>L’utente può, inoltre, formulare una richiesta di opposizione al trattamento dei suoi dati personali ex art. 21 del GDPR nella quale dare evidenza delle ragioni che giustifichino l’opposizione: il Titolare si riserva di valutare l’istanza, che potrebbe non essere accettata laddove sussistano obblighi o motivi legittimi prevalenti sugli interessi, diritti e libertà dell’utente e che impongano o giustifichino la prosecuzione del trattamento.
                <br>Le richieste inerenti l’esercizio dei summenzionati diritti devono essere rivolte all’attenzione del Titolare del sito actioavvocati.it con sede in Via Carducci 46 – 70016 Noicàttaro (BA) oppure all’indirizzo e-mail: info@actioavvocati.it
            </div>

            <div style="margin-top:40px;">
                <span style="font-weight: 600; font-size: 19px;">Aggiornamenti della presente Privacy e Cookie Policy</span>
                <br>Le notizie qui rese possono essere soggette a revisione in seguito a:
                <br>- modifiche della normativa di privacy, per gli aspetti qui di interesse;
                <br>- implementazioni tecnologiche del sito che impattino sulle attuali modalità di trattamento;
                <br>- variazioni organizzative nella struttura di privacy del Titolare che possano interessare l’utente.
                <br>Gli utenti sono cortesemente invitati a visitare periodicamente la presente Policy di modo da essere costantemente aggiornati sulle caratteristiche del trattamento.
            </div>
        </div>
    </div>

    <app-footer></app-footer>
  </div>
</section>