<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div style="padding-top:40px; padding-bottom:80px; padding-left:50px; padding-right:50px;">
      <div style="position: relative;">
        
        <swiper class="swiper-container" [config]="config" fxFlex="auto">
          <div *ngFor="let item of imgGallery" class="swiper-slide">
              <div fxLayout="row" fxLayoutAlign="center center" [style.backgroundImage]="'url('+ item.path +')'" style="width:100%; height:40vw; background-size:cover; background-position:center;">
                  <div fxFlex="25" style="height: 100%;">
                      
                  </div>
                  <div fxFlex="50" style="height: 100%;" > 
                     
                  </div>
                  <div fxFlex="25" style="height: 100%;">
                      
                  </div>
              </div>
          </div>
        </swiper>

        <div class="font-50-300 color-white" style="width: 100%; text-align: center; position: absolute; top:20vw; z-index: 99;">
            benvenuto in <span style="font-weight: 900">actioavvocati.it</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding-left:50px; padding-right:50px;">
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
        Lo studio
      </div>
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="start start" style="height: 400px; padding-left: 50px; padding-right:50px; margin-top:60px;">
        <div fxFlex="50">
          <div style="background-image: url('./assets/img/home_sinergia.jpg'); background-size:cover; background-position:0% 20%; width: 100%; height: 400px;">
            
          </div>
        </div>
        <div fxFlex="50" style="padding-left: 50px; height: 100%;" fxLayout="column" fxLayoutAlign="center start">
          <div class="font-40-300 color-primary" style="line-height: 35px">
            <span style="font-weight: 900;">La forza della sinergia</span>
          </div>
          <div class="font-22-300 color-grigio" style="margin-top:20px;">
            Un team di professionisti dinamico, preparato e sempre attento alle esigenze del cliente.
            <br>La scelta smart per la tua consulenza    
          </div>
          <div style="margin-top:40px;">
            <button (click)="gotoLink('/study')" class="button-border">Scopri di più</button>
          </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; padding-top:60px; padding-left:50px; padding-right:50px; margin-top:40px;">
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
        Chi siamo
      </div>
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" style="background-color: #F7F7F7; padding-left: 50px; padding-right:50px; padding-top:60px; padding-bottom:60px;">
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_01_parisi.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Nicola Parisi
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              parisi@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_nicola_parisi')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_02_dalessandro.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Vito D’Alessandro
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              dalessandro@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_vito_d_alessandro')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_03_morelli.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Annalisa Morelli
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
            morelli@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_annalisa_morelli')" class="button-border">About me</button>
          </div>
        </div>
        <div fxFlex="25" fxLayout="column" fxLayoutAlign="start center">
          <div style="background-image: url('./assets/img/chisiamo_04_positano.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
            
          </div>
          <div class="font-22-500 color-primary" style="margin-top:20px;">
            Avv. Roberto Positano
          </div>
          <!--
          <div class="font-16-400 color-grigio">
              MANSIONE O AREA
          </div>
          -->
          <div class="font-15-300 color-grigio">
              positano@actioavvocati.it
          </div>
          <div style="margin-top:20px;">
            <button (click)="gotoLink('/about/avv_roberto_positano')" class="button-border">About me</button>
          </div>
        </div>
    </div>


    <div fxLayout="row" fxLayoutAlign="start center" style="padding-left:50px; padding-right:50px; margin-top:60px;">
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
        Settori
      </div>
      <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
        <!--
        <div fxFlex="34" style="padding-left: 25px; padding-right: 25px;">
          <div class="font-40-300 color-primary" style="line-height: 35px">
              Offriamo la massima <span style="font-weight: 900;">competenza</span> per ogni <span style="font-weight: 900;">area tematica</span>
          </div>
          <div class="font-18-300 color-grigio" style="margin-top:20px;">
            Clicca su un area tematica per approfondire i nostri servizi    
          </div>
        </div>
        -->
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_scolastico')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioA_scuola.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto scolastico
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/compliance_aziendale')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioB_compliance2.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Compliance aziendale
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
        <div fxFlex="34" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/privacy_gdpr')">
          <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioC_privacy.jpg'); background-size:cover; background-position:center;">

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
            Privacy e GDPR
            <i class="fas fa-arrow-right"></i>
          </div>
          <!--
          <div class="font-18-300 color-grigio" style="margin-top:10px;">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
          </div>
          <div style="margin-top:20px;">
            <button class="button-border">Approfondisci</button>
          </div>
          -->
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px;">
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_lavoro_previdenza')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioD_lavoro.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto del lavoro e della previdenza sociale
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_famglia_persone')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioE_famiglia.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto di famiglia e delle persone
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
        <div fxFlex="34" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_amministrativo_pa')">
          <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioF_diritto_amministrativo.jpg'); background-size:cover; background-position:center;">

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
            Diritto amministrativo e formazione PA
            <i class="fas fa-arrow-right"></i>
          </div>
          <!--
          <div class="font-18-300 color-grigio" style="margin-top:10px;">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
          </div>
          <div style="margin-top:20px;">
            <button class="button-border">Approfondisci</button>
          </div>
          -->
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px;">
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_civile_commerciale')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioG_diritto_commerciale.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto civile e commerciale
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
        <div fxFlex="33" style="padding-left: 25px; padding-right: 25px; cursor: pointer;" (click)="gotoLink('/service/diritto_ecclesiastico_terzo_settore')">
            <div style="width:100%; height: 18vw; background-image: url('./assets/img/servizioH_terzosettore.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto Ecclesiastico e del Terzo settore
              <i class="fas fa-arrow-right"></i>
            </div>
            <!--
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
            -->
        </div>
      </div>
        
      
      <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:50px; padding-right:50px; margin-top:60px;">
        <div class="font-22-300 color-white">
          Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
        </div>
        <button (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
      </div>  

    <app-footer></app-footer>
  </div>
</section>





<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div style="padding-bottom:40px;">
      <div style="position: relative;">
        
        <swiper class="swiper-container" [config]="config" fxFlex="auto">
          <div *ngFor="let item of imgGallery" class="swiper-slide">
              <div fxLayout="row" fxLayoutAlign="center center" [style.backgroundImage]="'url('+ item.path +')'" style="width:100%; height:100vw; background-size:cover; background-position:center;">
                  <div fxFlex="25" style="height: 100%;">
                      
                  </div>
                  <div fxFlex="50" style="height: 100%;" > 
                     
                  </div>
                  <div fxFlex="25" style="height: 100%;">
                      
                  </div>
              </div>
          </div>
        </swiper>

        <div class="font-40-300 color-white" style="line-height: 40px; width: 100%; text-align: center; position: absolute; top:65vw; z-index: 99;">
            benvenuto in <span style="font-weight: 900">actioavvocati.it</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding-left:15px; padding-right:15px;">
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="50" style="text-align: center;" class="font-30-900 color-primary">
        Lo studio
      </div>
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" style="margin-top:40px;">
       
        <div style="background-image: url('./assets/img/home_sinergia.jpg'); background-size:cover; background-position:0% 20%; width: 100%; height: 250px;">
          
        </div>
    
        <div style="padding-left: 15px; padding-right: 15px; margin-top: 40px;" fxLayout="column" fxLayoutAlign="center start">
          <div class="font-40-300 color-primary" style="line-height: 35px">
            <span style="font-weight: 300;">La forza della </span><span style="font-weight: 900;">sinergia</span>
          </div>
          <div class="font-20-300 color-grigio" style="margin-top:20px;">
            Un team di professionisti dinamico, preparato e sempre attento alle esigenze del cliente.
            <br>La scelta smart per la tua consulenza    
          </div>
          <div style="margin-top:40px; text-align: center; width: 100%;">
            <button (click)="gotoLink('/study')" class="button-border">Scopri di più</button>
          </div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; margin-top: 20px; padding-top:20px;">
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="50" style="text-align: center;" class="font-30-900 color-primary">
        Chi siamo
      </div>
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>

    <div style="padding-top: 40px; padding-bottom: 20px; background-color: #F7F7F7;">
      <swiper class="swiper-container" [config]="configChisiamo" fxFlex="auto">
        <div *ngFor="let item of chisiamoArray" class="swiper-slide">
          <div fxLayout="column" fxLayoutAlign="start center">
            <div [style.backgroundImage]="'url('+ item.path +')'" style="background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              {{item.name}}
            </div>
            <div class="font-15-300 color-grigio">
                {{item.email}}
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink(item.link)" class="button-border">About me</button>
            </div>
          </div>
        </div>
      </swiper>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding-left:15px; padding-right:15px; margin-top: 40px;">
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
      <div fxFlex="50" style="text-align: center;" class="font-30-900 color-primary">
        Settori
      </div>
      <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px;">
        <div style="cursor: pointer; width: 100%;" (click)="gotoLink('/service/diritto_scolastico')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioA_scuola.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto scolastico
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/compliance_aziendale')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioB_compliance2.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Compliance aziendale
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/privacy_gdpr')">
          <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioC_privacy.jpg'); background-size:cover; background-position:center;">

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
            Privacy e GDPR
            <i class="fas fa-arrow-right"></i>
          </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/diritto_lavoro_previdenza')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioD_lavoro.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto del lavoro e della previdenza sociale
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/diritto_famglia_persone')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioE_famiglia.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto di famiglia e delle persone
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/diritto_amministrativo_pa')">
          <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioF_diritto_amministrativo.jpg'); background-size:cover; background-position:center;">

          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
            Diritto amministrativo e formazione PA
            <i class="fas fa-arrow-right"></i>
          </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/diritto_civile_commerciale')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioG_diritto_commerciale.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto civile e commerciale
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
        <div style="cursor: pointer; width: 100%; margin-top: 40px;" (click)="gotoLink('/service/diritto_ecclesiastico_terzo_settore')">
            <div style="width:100%; height: 50vw; background-image: url('./assets/img/servizioH_terzosettore.jpg'); background-size:cover; background-position:center;">

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="font-18-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto Ecclesiastico e del Terzo settore
              <i class="fas fa-arrow-right"></i>
            </div>
        </div>
      </div>
        
      
      <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:15px; padding-right:15px; margin-top:40px;">
        <div class="font-22-300 color-white" style="text-align: center;">
          Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
        </div>
        <button style="margin-top: 20px;" (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
      </div>  

    <app-footer></app-footer>
  </div>
</section>


<!--
<section *ngIf="0" fxLayout="column" fxLayoutAlign="space-between center" style="width:100%; height:100%; background-color: rgb(14,56,98);" fxHide fxShow.md fxShow.gt-md>

  <div style="margin-top:40px;">
    <img src="./assets/img/logo/logo_actio_white.svg" width="300px">
  </div>

  <div style="text-align: center; line-height: 50px;">
    <span class="color-white font-60-300">actioavvocati.it <span style="font-weight: 700;">coming soon</span></span>
  </div>

  <div class="color-white font-16-400" style="margin-bottom:40px;">
    actioavvocati - via luzzatti, 15 - 70125 bari<br>
    via carducci, 46 -70016 noicàttaro (BA)<br>
    tel. 080.4782868 - info@actioavvocati.it
  </div>

</section>

<section fxLayout="column" fxLayoutAlign="space-between center" style="width:100%; height:100%; background-color: rgb(14,56,98);" fxHide fxShow.lt-md>

  <div style="margin-top:40px;">
    <img src="./assets/img/logo/logo_actio_white.svg" width="200px">
  </div>

  <div style="text-align: center; line-height: 40px;">
    <span class="color-white font-50-300">actioavvocati.it <span style="font-weight: 700;">coming soon</span></span>
  </div>

  <div class="color-white font-16-400" style="text-align: center; margin-bottom:40px;">
    actioavvocati - via luzzatti, 15 - 70125 bari<br>
    via carducci, 46 -70016 noicàttaro (BA)<br>
    tel. 080.4782868 - info@actioavvocati.it
  </div>

</section>
-->