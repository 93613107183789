import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {

  objectKey = Object.keys; 

  public menuIndex = 'contact';

  public spinnerContact;
  public contactList;

  constructor(public db: AngularFireDatabase) { 
    this.getContactlist();
  }

  ngOnInit(): void {
  }

  getContactlist(){
    this.db.object('contact').valueChanges().subscribe( action =>{
      this.contactList = action;
      console.log(this.contactList);
    });
  }

}
