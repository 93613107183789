import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ContactListComponent } from './pages/contact-list/contact-list.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { ServiceComponent } from './pages/service/service.component';
import { StudyComponent } from './pages/study/study.component';
import { CookieComponent } from './pages/cookie/cookie.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { NewsCreateComponent } from './pages/news-create/news-create.component';
import { NewsUpdateComponent } from './pages/news-update/news-update.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about/:idpersona',
    component: AboutComponent,
  },
  {
    path: 'service/:idservice',
    component: ServiceComponent,
  },
  {
    path: 'news',
    component: NewsComponent,
  },
  {
    path: 'news-detail/:idnews',
    component: NewsDetailComponent,
  },
  {
    path: 'news-create',
    component: NewsCreateComponent,
  },
  {
    path: 'news-update/:idnews',
    component: NewsUpdateComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'contact-list',
    component: ContactListComponent,
  },
  {
    path: 'study',
    component: StudyComponent,
  },
  {
    path: 'cookie-policy',
    component: CookieComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
