import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public configChisiamo: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    autoplay: true,
  };
  
  public chisiamoArray = [
    {
      name: 'Avv. Nicola Parisi',
      path: './assets/img/chisiamo_01_parisi.jpg',
      link: '/about/avv_nicola_parisi',
      email: 'parisi@actioavvocati.it'
    },
    {
      name: ' Avv. Vito D’Alessandro',
      path: './assets/img/chisiamo_02_dalessandro.jpg',
      link: '/about/avv_vito_d_alessandro',
      email: 'dalessandro@actioavvocati.it'
    },
    {
      name: 'Avv. Annalisa Morelli',
      path: './assets/img/chisiamo_03_morelli.jpg',
      link: '/about/avv_annalisa_morelli',
      email: 'morelli@actioavvocati.it'
    },
    {
      name: 'Avv. Roberto Positano',
      path: './assets/img/chisiamo_04_positano.jpg',
      link: '/about/avv_roberto_positano',
      email: 'positano@actioavvocati.it'
    },
  ];


  public menuIndex = 'home';
  public idpersona;

  public about = {
    avv_nicola_parisi : {
      nome: 'Avv. Nicola Parisi',
      titolo: 'Nicola Parisi',
      email: 'parisi@actioavvocati.it',
      img: './assets/img/chisiamo_01_parisi.jpg',
      biografia:  `Nato nel 1972, si laurea nell’Università degli Studi di Bari col massimo dei voti nel 1997. 
      <br>Da subito intraprende il percorso di praticantato forense in diritto amministrativo e si abilita al patrocinio nel 1998.
      <br>Conseguito il titolo di Avvocato nel 2001, inizia l’attività libero professionale occupandosi di ambiti tradizionali del diritto ma approfondendo parallelamente lo studio di settori giuridici emergenti tanto da conseguire nel 2006 il Master di II livello in Diritto dell’Internet e delle Tecnologie Informatiche presso il Centro Studi Informatica Giuridica.
      <br>È iscritto all’Albo degli Avvocati patrocinanti innanzi alle Magistrature Superiori.`,
      competenza: `È consulente legale di varie società operanti nel settore del commercio elettronico, farmaceutico e dei contratti pubblici.
      <br>Ha maturato una considerevole esperienza nell’ambito del <span class="font-18-500 color-primary"">diritto scolastico</span> anche grazie alla collaborazione con sindacati e associazioni di categoria. L’operatività nel settore spazia dalla consulenza legale all’assistenza giudiziale di Dirigenti Scolastici, DSGA, docenti e personale ATA.
      <br><span class="font-18-500 color-primary">È relatore per conto di Società accreditate alla formazione presso enti pubblici</span> in materia di trasparenza amministrativa, tutela del diritto alla riservatezza (privacy), procedimenti disciplinari, appalti pubblici, amministrazione digitale (CAD), anticorruzione (codice di comportamento dei dipendenti pubblici, whistleblower). È docente in master universitari di diritto scolastico.
      <br>Riveste l’incarico di <span class="font-18-500 color-primary">Responsabile per la Protezione dei dati personali</span> (DPO) per soggetti pubblici (Istituti Scolastici ed enti) e privati (società commerciali, associazioni di categoria, cooperative, organizzazioni sindacali ed enti di patronato) e presta attività di consulenza in tale settore.`,
    },
    avv_vito_d_alessandro:{
      nome: 'Avv. Vito D’Alessandro',
      titolo: 'Vito D’Alessandro',
      email: 'dalessandro@actioavvocati.it',
      img: './assets/img/chisiamo_02_dalessandro.jpg',
      biografia:  `Nato nel 1973, si è laureato con lode presso l’Università degli Studi di Bari nel 1997. 
      <br>Nel 1999 ha conseguito, con il massimo dei voti, la Specializzazione Universitaria in Diritto ed Economia dell’Unione Europea presso la facoltà di Economia dell’Università di Bari.
      <br>Intrapresa da subito la pratica professionale, ha frequentato la Suola Forense presso l’Ordine degli Avvocati di Bari e nel 1998 si è abilitato al patrocinio. 
      <br>Nel 2000 ha conseguito il titolo di Avvocato.`,
      competenza: `Dopo i primi anni di attività professionale dedicati prevalentemente al diritto amministrativo e degli appalti ha esteso la sua specializzazione alla consulenza aziendale e societaria, avviando rapporti continuativi con aziende operanti sui mercati italiani ed esteri. Nell’ambito di tali rapporti si occupa, tra l’altro, di <span class="font-18-500 color-primary">contrattualistica</span>, anche <span class="font-18-500 color-primary">internazionale</span>, di privacy e GDPR, di sicurezza del lavoro, di <span class="font-18-500 color-primary">internal audit</span> e sistemi di qualità.
      <br>Avendo acquisito esperienza nel settore dell’organizzazione aziendale, ha lavorato alla predisposizione di molteplici <span class="font-18-500 color-primary">Modelli Organizzativi ex D.Lgs. 231/2001</span> e, attualmente, ricopre la funzione di <span class="font-18-500 color-primary">O.d.V.</span> per diverse società.
      <br>Ha esteso ormai da anni la sua attività di consulenza ad Enti Ecclesiastici e del Terzo Settore che segue per la definizione di tutte le problematiche attinenti la gestione del patrimonio e delle attività istituzionali. `,
    },
    avv_annalisa_morelli:{
      nome: 'Avv. Annalisa Morelli',
      titolo: 'Annalisa Morelli',
      email: 'morelli@actioavvocati.it',
      img: './assets/img/chisiamo_03_morelli.jpg',
      biografia:  `Nata nel 1975, si laurea con lode nel 2000 discutendo la tesi in diritto civile nell’Università degli Studi di Bari.
      <br>Da subito intraprende il percorso di formazione forense nel settore civile conseguendo il titolo di Avvocato nel 2003.`,
      competenza: `Ha scelto di occuparsi del diritto dal “volto umano”, il <span class="font-18-500 color-primary">diritto di famiglia e delle persone</span>, con la convinzione che il fallimento dei legami affettivi debba essere gestito con razionalità e tecnica giuridica ma anche con empatia e solidarietà.
      Parallelamente ha maturato varie esperienze nella gestione di questioni successorie in ambito sia giudiziario (impugnazione di testamenti; contenziosi per lesioni di legittima) che consulenziale (redazione dichiarazioni di successione; ausilio nella predisposizione di atti di ultima volontà).
      <br>Presta attività di assistenza in favore di soggetti pubblici e privati in materia di tutela del diritto alla riservatezza (già Codice Privacy ora GDPR 2016/679).
      <br>Affianca l’Avv. Nicola Parisi nell’<span class="font-18-500 color-primary">attività di formazione</span> (tutela del diritto alla riservatezza; trasparenza amministrativa, responsabilità civile, cyber bullismo) a favore del personale della Scuola e nella gestione del contenzioso in tale settore.`
      ,
    },
    avv_roberto_positano:{
      nome: 'Avv. Roberto Positano',
      titolo: 'Roberto Positano',
      email: 'positano@actioavvocati.it',
      img: './assets/img/chisiamo_04_positano.jpg',
      biografia: `Nato nel 1976, si laurea nel 2005 discutendo la tesi in Scienze delle Finanze e Diritto Tributario all’Università degli Studi di Bari.
      <br>Già durante il percorso di studi matura varie esperienze lavorative in settori di intreccio e cura delle relazioni interpersonali che conferiscono una specifica connotazione alla sua successiva impostazione professionale.`,
      competenza: `Conseguito il titolo di Avvocato, inizia la sua carriera occupandosi di diritto tributario e diritto civile (tutela del consumatore e infortunistica stradale).
      <br>L’inizio della collaborazione con diverse sigle sindacali segna una svolta nel suo orientamento professionale; approfondisce lo studio del <span class="font-18-500 color-primary">diritto del lavoro e della previdenza sociale</span> maturando esperienza nella gestione amministrativa delle richieste di prestazioni previdenziali e assistenziali (assegno mensile, indennità d’accompagnamento, pensione d’inabilità, assegni legge 222/84) e nel campo medico-legale; presta assistenza giudiziaria nel contenzioso previdenziale e lavoristico.
      <br>Membro dal 2018 della Commissione Lavoro dell’Ordine degli Avvocati di Bari, è <span class="font-18-500 color-primary">autore di varie pubblicazioni sul Notiziario Lavoro</span> e partecipa alla gestione dell’attività di formazione a favore degli iscritti.`,
    }
  }

  public item;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe((params: ParamMap) => {
      this.idpersona = params['idpersona']; 
      this.item = this.about[this.idpersona];
    });
  }

  ngOnInit() {
  }

  gotoLink(link){
    console.log(link);
    window.location.hash = 'home-top';
    this.router.navigate([link]);
  }
}
