<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
        
        <div *ngIf="user" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
            <div fxLayout="column" fxLayoutAlign="start start">
                <div class="font-22-500" style="width:100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
                    Crea una News
                </div>
                <div class="font-22-500 color-primary" style="width:100%; margin-top:20px; text-align: center;">
                    Inserisci il titolo, un testo e una immagine.
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" style="margin-top:40px; position: relative; margin-bottom: 5px;">
                <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
                    <label style="cursor: pointer; width: 100%;">
                        <input type="file" (change)="onFileChanged($event)" multiple/>
                        <div class="button-gallery" style="width: 100%; text-align: center; height: 40px;">
                            <span style="top: 12px; left: 8px; position: relative;"><i class="fas fa-images"></i>&nbsp;&nbsp;Carica una immagine</span>
                        </div>                
                    </label>
                </div>
                <div *ngIf="showSpinnerLoad" [style.width.%]="percentual" style="margin-top: 5px; height: 4px; background-color: #db1c1c;"></div>
                <div fxLayout="row container" fxLayoutAlign="start start" style="margin-top: 5px;">
                    <div *ngFor="let item of docImg; let i = index;" style="border-radius: 4px; margin-right: 10px; margin-bottom: 10px; width: 100px; height: 100px; background-position: center; background-size: cover; background-color: #c9c9c9;" [style.backgroundImage]="'url(' + item.url_image + ')'">
                        <div (click)="removePhoto(i)" style="position: relative; top:5px; right: -81px; cursor: pointer;"><i class="fas fa-times"></i></div>
                    </div>
                </div> 
            </div>    

            <form [formGroup]="newsForm" (ngSubmit)="create()" style="width:100%;">
                <div style="margin-top:40px; width:100%;">
                    <input autocomplete="nope" class="input-actio" placeholder="Titolo" formControlName="titolo">
                </div>
        
                <div style="margin-top:40px; width:100%;">
                    <textarea autocomplete="nope" rows="10" class="input-actio-textarea" placeholder="testo" formControlName="testo"></textarea>
                </div> 

                <div *ngIf="message" class="font-16-500" style="color:red; padding-top:20px;">
                    {{message}}
                </div>

                <div>
                    <button class="button-border" style="width: 100%; margin-top: 40px;">Salva</button>
                </div>
            </form>
        </div>
  
        <app-footer></app-footer>
    </div>
</section>