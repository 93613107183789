<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div *ngIf="news" class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
        
        <div *ngIf="user" style="padding-left: 50px; padding-top: 50px;">
            <button routerLink="/news-create" class="button-border">Crea una news</button>
        </div>
        
        <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
            <div class="font-22-500" style="width:100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
                News
            </div>
            <div class="font-22-500 color-primary" style="width:100%; margin-top:20px; text-align: center;">
            Elenco news
            </div>
        </div>

        
        <div *ngFor="let chiave of objectKey(news); let index = index">
            <div fxLayout="row" fxLayoutAlign="start start" style="height: 300px; padding-left: 50px; padding-right:50px; margin-top:60px;">
                <div fxFlex="50" *ngIf="news[chiave].image">
                    <div [style.backgroundImage]="'url('+ news[chiave].image[0].url_image +')'" style="background-size:cover; background-position:0% 20%; width: 100%; height: 300px;">
                        
                    </div>
                </div>
                <div fxFlex="50" *ngIf="!news[chiave].image">
                    <div style="background-color: gainsboro; width: 100%; height: 300px;">
                        
                    </div>
                </div>
                <div fxFlex="50" style="padding-left: 50px; height: 100%;" fxLayout="column" fxLayoutAlign="center start">
                    <div class="font-40-300 color-primary" style="line-height: 35px">
                        <span style="font-weight: 900;">{{news[chiave].titolo}}</span>
                    </div>
                    <div class="font-22-300 color-primary" style="line-height: 22px; margin-top: 5px;">
                        <span>{{news[chiave].created_at | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div class="font-22-300 color-grigio" style="margin-top:20px;">
                        <span [innerHTML]="news[chiave].testo | slice:0:100"></span> ...
                    </div>
                    <div style="margin-top:40px;">
                        <button [routerLink]="['/news-detail', chiave]" class="button-border">Leggi tutto</button>
                    </div>
                </div>
            </div>
        </div>
  
        <app-footer></app-footer>
    </div>
</section>