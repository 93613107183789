<section style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
      
      <div id="home-top" fxLayout="row" fxLayoutAlign="start start" style="padding-left:50px; padding-right:50px; padding-top: 60px;">
        <div fxFlex="40" fxLayout="column" fxLayoutAlign="start center">
          <div fxLayout="column" fxLayoutAlign="start center" style="width:300px; max-width:300px;">
              <div [style.backgroundImage]="'url('+ item.img +')'" style="background-size:cover; background-position:0% 20%; width: 300px; height: 300px; border-radius: 50%;">
                
              </div>
              <div class="font-22-500" style="width: 100%; margin-top:20px; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
                 {{item.nome}}
              </div>
              <!--
              <div class="font-18-400 color-primary" style="margin-top: 20px;">
                MANSIONE AREA
              </div>
              -->
              <div style="background-color: #E2E2E2; height: 1px; width:100%; margin-top:40px;"></div>
              <div fxLayout="column" fxLayoutAlign="center center"  class="font-18-300 color-grigio" style="height: 50px;">
                {{item.email}}
              </div>
              <!--
              <div style="background-color: #E2E2E2; height: 1px; width:100%;"></div>
              <div fxLayout="column" fxLayoutAlign="center center"  class="font-18-300 color-grigio" style="height: 50px;">
                  +39 333.123.456.789
              </div>
              -->
              <div style="background-color: #E2E2E2; height: 1px; width:100%;"></div>
          </div>
        </div>
        <div fxFlex="60">
          <div class="font-32-700 color-primary" style="margin-top:20px;">
            {{item.titolo}}
          </div>
          <div class="font-24-700 color-primary" style="margin-top:20px;">
            Biografia e curriculum
          </div>
          <div class="font-18-300 color-grigio" style="margin-top:10px;" [innerHtml]="item.biografia"></div>
          <div class="font-24-700 color-primary" style="margin-top:20px;">
            Settori di competenza
          </div>
          <div class="font-18-300 color-grigio" style="margin-top:10px;" [innerHtml]="item.competenza"></div>
          <!--
          <div style="margin-top:20px;">
              <button class="button-border">Scarica CV</button>
          </div>
          -->
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top:60px; background-color: #003664; padding-left: 50px; padding-right:50px; padding-top:20px; padding-bottom:20px;">
          <div class="font-22-300 color-white">
            Per qualsiasi informazione <span style="font-weight:500;">scrivici un messaggio</span> attraverso il form di contatto.  
          </div>
          <div>
            <button (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
          </div>
      </div>
        
      <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; padding-top:60px; padding-left:50px; padding-right:50px;">
          <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
          <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
            Chi siamo
          </div>
          <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
        </div>
    
        <div fxLayout="row" fxLayoutAlign="start start" style="background-color: #F7F7F7; padding-left: 50px; padding-right:50px; padding-top:60px; padding-bottom:60px;">
          <div *ngIf="idpersona!='avv_nicola_parisi'" fxFlex="33" fxLayout="column" fxLayoutAlign="start center">
            <div style="background-image: url('./assets/img/chisiamo_01_parisi.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              Avv. Nicola Parisi
            </div>
            <!--
            <div class="font-16-400 color-grigio">
                MANSIONE O AREA
            </div>
            -->
            <div class="font-15-300 color-grigio">
                parisi@actioavvocati.it
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink('/about/avv_nicola_parisi')" class="button-border">About me</button>
            </div>
          </div>
          <div *ngIf="idpersona!='avv_vito_d_alessandro'" fxFlex="33" fxLayout="column" fxLayoutAlign="start center">
            <div style="background-image: url('./assets/img/chisiamo_02_dalessandro.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              Avv. Vito D’Alessandro
            </div>
            <!--
            <div class="font-16-400 color-grigio">
                MANSIONE O AREA
            </div>
            -->
            <div class="font-15-300 color-grigio">
                dalessandro@actioavvocati.it
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink('/about/avv_vito_d_alessandro')" class="button-border">About me</button>
            </div>
          </div>
          <div *ngIf="idpersona!='avv_annalisa_morelli'" fxFlex="33" fxLayout="column" fxLayoutAlign="start center">
            <div style="background-image: url('./assets/img/chisiamo_03_morelli.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              Avv. Annalisa Morelli
            </div>
            <!--
            <div class="font-16-400 color-grigio">
                MANSIONE O AREA
            </div>
            -->
            <div class="font-15-300 color-grigio">
              morelli@actioavvocati.it
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink('/about/avv_annalisa_morelli')" class="button-border">About me</button>
            </div>
          </div>
          <div *ngIf="idpersona!='avv_roberto_positano'" fxFlex="33" fxLayout="column" fxLayoutAlign="start center">
            <div style="background-image: url('./assets/img/chisiamo_04_positano.jpg'); background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
              Avv. Roberto Positano
            </div>
            <!--
            <div class="font-16-400 color-grigio">
                MANSIONE O AREA
            </div>
            -->
            <div class="font-15-300 color-grigio">
                positano@actioavvocati.it
            </div>
            <div style="margin-top:20px;">
              <button (click)="gotoLink('/about/avv_roberto_positano')" class="button-border">About me</button>
            </div>
          </div>
      </div>
  
      <app-footer></app-footer>
    </div>
</section>



<section style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
      
      <div id="home-top" fxLayout="column" fxLayoutAlign="start start" style="padding-left:15px; padding-right:15px; padding-top: 40px;">
        <div fxLayout="column" fxLayoutAlign="start center" style="width: 100%;"> 
            <div [style.backgroundImage]="'url('+ item.img +')'" style="background-size:cover; background-position:0% 20%; width: 300px; height: 300px; border-radius: 50%;">
              
            </div>
            <div class="font-22-500" style="width: 100%; margin-top:20px; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
                {{item.nome}}
            </div>
            <!--
            <div class="font-18-400 color-primary" style="margin-top: 20px;">
              MANSIONE AREA
            </div>
            -->
            <div style="background-color: #E2E2E2; height: 1px; width:100%; margin-top:40px;"></div>
            <div fxLayout="column" fxLayoutAlign="center center"  class="font-18-300 color-grigio" style="height: 50px;">
              {{item.email}}
            </div>
            <!--
            <div style="background-color: #E2E2E2; height: 1px; width:100%;"></div>
            <div fxLayout="column" fxLayoutAlign="center center"  class="font-18-300 color-grigio" style="height: 50px;">
                +39 333.123.456.789
            </div>
            -->
            <div style="background-color: #E2E2E2; height: 1px; width:100%;"></div>
        </div>
        
        <div>
          <div class="font-32-700 color-primary" style="margin-top:40px;">
            {{item.titolo}}
          </div>
          <div class="font-24-700 color-primary" style="margin-top:20px;">
            Biografia e curriculum
          </div>
          <div class="font-18-300 color-grigio" style="margin-top:10px;" [innerHtml]="item.biografia"></div>
          <div class="font-24-700 color-primary" style="margin-top:20px;">
            Settori di competenza
          </div>
          <div class="font-18-300 color-grigio" style="margin-top:10px;" [innerHtml]="item.competenza"></div>
          <!--
          <div style="margin-top:20px;">
              <button class="button-border">Scarica CV</button>
          </div>
          -->
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:15px; padding-right:15px; margin-top:40px;">
        <div class="font-22-300 color-white" style="text-align: center;">
          Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
        </div>
        <button style="margin-top: 20px;" (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
      </div>  
  
      <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #F7F7F7; padding-top:40px;">
        <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
        <div fxFlex="50" style="text-align: center;" class="font-30-900 color-primary">
          Chi siamo
        </div>
        <div fxFlex="25" style="width: 100%; height: 1px; background-color: #003664;"></div>
      </div>
  
      <div style="padding-top: 40px; padding-bottom: 20px; background-color: #F7F7F7;">
        <swiper class="swiper-container" [config]="configChisiamo" fxFlex="auto">
          <div *ngFor="let item of chisiamoArray" class="swiper-slide">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div [style.backgroundImage]="'url('+ item.path +')'" style="background-size:cover; background-position:0% 20%; width: 200px; height: 200px; border-radius: 50%;">
                
              </div>
              <div class="font-22-500 color-primary" style="margin-top:20px;">
                {{item.name}}
              </div>
              <div class="font-15-300 color-grigio">
                  {{item.email}}
              </div>
              <div style="margin-top:20px;">
                <button (click)="gotoLink(item.link)" class="button-border">About me</button>
              </div>
            </div>
          </div>
        </swiper>
      </div>
  
      <app-footer></app-footer>
    </div>
  </section>