<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px; margin-bottom: 60px;">
        <div fxFlex="100" style="padding-right:50px;">
          <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Contatti
          </div>
          <div style="margin-top:40px;" *ngIf="contactList">
              

            <div *ngFor="let chiave of objectKey(contactList); let index = index">
                <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:60px;">
                    <div fxFlex="100" style="padding-left: 50px; height: 100%;" fxLayout="column" fxLayoutAlign="center start">
                        <div class="font-40-300 color-primary" style="line-height: 35px">
                            <span style="font-weight: 900;">{{contactList[chiave].name}}</span>
                        </div>
                        <div class="font-22-300 color-primary" style="line-height: 22px; margin-top: 5px;">
                            <span>{{contactList[chiave].created_at | date: 'dd/MM/yyyy'}}</span>
                        </div>
                        <div>
                            {{contactList[chiave].email}}
                        </div>
                        <div>
                            {{contactList[chiave].message}}
                        </div>
                    </div>
                </div>
            </div>


          </div>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
</section>