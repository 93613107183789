<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->

    <div *ngIf="user" style="padding-left: 50px; padding-top: 50px;">
        <button routerLink="/contact-list" class="button-border">Lista contatti</button>
    </div>

    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px; margin-bottom: 60px;">
        <div fxFlex="50" style="padding-right:50px;">
          <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Contatti
          </div>
          <div style="margin-top:40px;">
              <div>
                  <div class="font-22-500 color-primary">
                      actioavvocati
                  </div>
                  <div class="font-18-300 color-grigio">
                      <div style="margin-top:20px">
                        Via Siderno, 10<br>
                        00178 Roma
                      </div>
                      <div style="margin-top:20px">
                        Via Luzzatti, 15<br>
                        70125 Bari
                      </div>
                      <div style="margin-top:20px">
                        Via Carducci, 46<br>
                        70016 Noicàttaro (Ba)<br>
                        Tel. 080.4782868
                      </div>
                      <div style="margin-top:20px">
                        info@actioavvocati.it<br>
                      </div>
                  </div>
              </div>
          </div>
          <div style="margin-top:40px;">
              <form autocomplete="nope" fxLayout="column" fxLayoutAlign="center start" [formGroup]="contactForm" (ngSubmit)="contactSend()" style="width:100%;">
          
                  <div class="font-22-500 color-primary">
                    Scrivici un messaggio
                  </div>
    
                  <div style="margin-top:20px; width:100%;">
                    <input autocomplete="nope" class="input-actio" placeholder="Nome / Società" formControlName="name">
                  </div>
    
                  <div style="margin-top:40px; width:100%;">
                    <input autocomplete="nope" class="input-actio" placeholder="Email" formControlName="email">
                  </div>
          
                  <div style="margin-top:40px; width:100%;">
                    <textarea autocomplete="nope" rows="10" class="input-actio-textarea" placeholder="Messaggio" formControlName="message"></textarea>
                  </div>
                  
                  <div style="width: 100%;">
                    <div *ngIf="message" class="font-16-500 color-white" style="background-color: #e63232; border-radius: 4px; padding-top:5px; padding-bottom: 5px; padding-left: 10px; padding-right: 10px;">
                      {{message}}
                    </div>
                  </div>
    
                  <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:20px; width: 100%;">
                    <div class="font-13-300 color-black">
                      <mat-checkbox formControlName="condizione"></mat-checkbox>&nbsp;&nbsp;Acconsento al trattamento dei dati inviati attraverso questo modulo
                    </div>
                    <button fxLayout="row" fxLayoutAlign="center center" class="button-border" type="submit" style="margin-left: 20px; width:100% !important;">
                        <div *ngIf="!spinnerContact">Invia messaggio</div>
                        <div *ngIf="spinnerContact"><mat-spinner [diameter]="20"></mat-spinner></div>
                    </button>
                  </div>
              </form>
          </div>
        </div>
        <div fxFlex="50">
            <!--
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.4818845658137!2d16.98859547429946!3d41.03658995524267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1347c15b410b7497%3A0x1766d6db5f255dbf!2sVia%20Giosu%C3%A8%20Carducci%2C%2046%2C%2070016%20Noicattaro%20BA!5e0!3m2!1sit!2sit!4v1567508748952!5m2!1sit!2sit" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
            -->
            <agm-map style="width:100%; height:500px;" [latitude]="latMap" [longitude]="lonMap" [(zoom)]="zoom" [mapTypeControl]="false">
              <agm-marker *ngFor="let coord of coords; let i=index;" 
                  [latitude]="coord.lat" 
                  [longitude]="coord.lng" 
                  [zIndex]="coord.zIndex"
                  [iconUrl]="coord.iconUrl">
              </agm-marker>
            </agm-map>  

            <div style="margin-top:40px;">
                <div class="font-22-500 color-primary">
                   Follow us
                </div>
                <div style="margin-top:10px;">
                    <i (click)="gotoFacebook()" style="cursor: pointer; color:#003664; font-size: 25px; margin-right: 10px;" class="fab fa-facebook-square"></i>
                    <i (click)="gotoLinkedin()" style="cursor: pointer; color:#003664; font-size: 25px;" class="fab fa-linkedin-in"></i>
                </div>
            </div>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
</section>


<section class="scroll-bar" style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px; margin-bottom: 40px;">
      <div class="font-22-500" style="width: 100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
          Contatti
      </div>
      <div style="margin-top:40px;">
          <div>
              <div class="font-22-500 color-primary">
                  actioavvocati
              </div>
              <div class="font-18-300 color-grigio">
                  <div style="margin-top:20px">
                    Via Siderno, 10<br>
                    00178 Roma
                  </div>
                  <div style="margin-top:20px">
                    Via Luzzatti, 15<br>
                    70125 Bari
                  </div>
                  <div style="margin-top:20px">
                    Via Carducci, 46<br>
                    70016 Noicàttaro (Ba)<br>
                    Tel. 080.4782868
                  </div>
                  <div style="margin-top:20px">
                    info@actioavvocati.it<br>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div style="width: 100%; margin-top: 40px;">
      <agm-map style="width:100%; height:400px;" [latitude]="latMap" [longitude]="lonMap" [(zoom)]="zoom" [mapTypeControl]="false">
        <agm-marker *ngFor="let coord of coords; let i=index;" 
            [latitude]="coord.lat" 
            [longitude]="coord.lng" 
            [zIndex]="coord.zIndex"
            [iconUrl]="coord.iconUrl">
        </agm-marker>
      </agm-map>  
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; margin-top:40px; margin-bottom: 40px;">   
      <div>
          <form autocomplete="nope" fxLayout="column" fxLayoutAlign="center start" [formGroup]="contactForm" (ngSubmit)="contactSend()" style="width:100%;">
      
              <div class="font-22-500 color-primary">
                Scrivici un messaggio
              </div>

              <div style="margin-top:20px; width:100%;">
                <input autocomplete="nope" class="input-actio" placeholder="Nome / Società" formControlName="name">
              </div>

              <div style="margin-top:40px; width:100%;">
                <input autocomplete="nope" class="input-actio" placeholder="Email" formControlName="email">
              </div>
      
              <div style="margin-top:40px; width:100%;">
                <textarea autocomplete="nope" rows="10" class="input-actio-textarea" placeholder="Messaggio" formControlName="message"></textarea>
              </div>

              <div style="width: 100%;">
                <div *ngIf="message" class="font-16-500 color-white" style="background-color: #e63232; border-radius: 4px; padding-top:5px; padding-bottom: 5px; padding-left: 10px; padding-right: 10px;">
                  {{message}}
                </div>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" style="margin-top:20px; width: 100%;">
                <div class="font-13-300 color-black">
                  <mat-checkbox formControlName="condizione"></mat-checkbox>&nbsp;&nbsp;Acconsento al trattamento dei dati inviati attraverso questo modulo
                </div>
                <button fxLayout="row" fxLayoutAlign="center center" class="button-border" type="submit" style="margin-left: 20px; width:100% !important;">
                    <div *ngIf="!spinnerContact">Invia messaggio</div>
                    <div *ngIf="spinnerContact"><mat-spinner [diameter]="20"></mat-spinner></div>
                </button>
              </div>
          </form>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</section>