import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireDatabase } from '@angular/fire/database';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  public menuIndex = 'news';
  public idnews;
  public newsDetail;

  constructor(public afAuth: AngularFireAuth, public db: AngularFireDatabase, private route: ActivatedRoute) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.idnews = params['idnews'];
      console.log(this.idnews);
      this.getNewsDetail();
    });
  }

  ngOnInit() {
  }

  getNewsDetail(){
    this.db.object('news/'+this.idnews).valueChanges().subscribe( action =>{
      this.newsDetail = action;
      console.log(this.newsDetail);
    });
  }

    
  textFormat(html){
    if(html){
      return html.replace(/\n/g, '<br/>');
    }
  }
}
