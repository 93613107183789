import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  public menuIndex = 'home';
  public idservice;
  public item;

  public service = {
    diritto_scolastico : {
      prev:'diritto_ecclesiastico_terzo_settore',
      next:'compliance_aziendale',
      titolo: 'Diritto scolastico',
      description: 'Il mondo della Scuola è regolato da una normativa frastagliata ed in continua evoluzione. L’assistenza legale in tale ambito necessita di un mix di competenze di diritto amministrativo e diritto del lavoro oltre che di una specifica conoscenza delle prassi e dei contratti collettivi del settore.',
      img: './assets/img/servizioA_scuola.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- impugnazione di bandi e concorsi 
      <br>- ricostruzione di carriera del personale docente e ATA
      <br>- mobilità e trasferimenti 
      <br>- riconoscimento dei diritti di precedenza (legge 104/1992)
      <br>- impugnazione delle sanzioni disciplinari
      <br>- riconoscimento dei titoli di studio (diploma magistrale ante 2001; ITP; titoli conseguiti all’estero)
      <br>- contenzioso sulle graduatorie di istituto, di merito e ad esaurimento
      <br>- impugnazione degli esiti di esami e scrutini
      
      <br><br>L’Avv. Nicola Parisi è consulente legale di organizzazioni sindacali e di categoria del settore scuola, DPO (responsabile per la protezione dei dati personali) di numerosi Istituti Scolastici della Puglia e docente in master universitari in diritto scolastico.
      `,
    },
    compliance_aziendale : {
      prev:'diritto_scolastico',
      next:'privacy_gdpr',
      titolo: 'Compliance aziendale',
      description: 'Operare per la compliance vuol dire curare e consolidare l’immagine aziendale in riferimento alla correttezza delle procedure ed al rispetto delle norme, al fine di garantire la conformità alle prescrizioni di legge per prevenire irregolarità e sanzioni che potrebbero danneggiare l’azienda e tutti i suoi stakeholders. ',
      img: './assets/img/servizioB_compliance2.jpg',
      testo:  `ActioAvvocati supporta i suoi clienti per tutte le attività e le problematiche attinenti la tutela e la sicurezza sui luoghi di lavoro (D.Lgs. 81/2008), la responsabilità amministrativa delle persone giuridiche (D.Lgs. 231/01), il Regolamento Europeo sulla Privacy (GDPR Reg. UE 679/2016), l’internal audit, i sistemi di certificazione della qualità.
      <br><br>
      L’Avv. Vito D’Alessandro ha lavorato alla predisposizione di molteplici Modelli di Organizzazione Aziendale di cui al D.Lgs. 231/2008 nonché di M.O.G. (D.Lgs. 81/2008) ed, attualmente, ricopre l’incarico di Organismo di Vigilanza per diverse società e gruppi aziendali.
      <br><br>
      L’Avv. Nicola Parisi assiste società operanti nel settore dell’IT nella configurazione di Sistemi Integrati di Gestioni propedeutiche all’ottenimento di certificazioni di qualità e AGID.
      `,
    },
    privacy_gdpr : {
      prev:'compliance_aziendale',
      next:'diritto_lavoro_previdenza',
      titolo: 'Privacy e GDPR',
      description: 'Il principio di accountability (responsabilizzazione) introdotto dal Regolamento Europeo sulla tutela del diritto alla riservatezza, obbliga tutti coloro che trattano dati personali ad adeguare la propria attività ai canoni di privacy by default e privacy by design e quindi a considerare la tutela del diritto alla riservatezza come un assioma della propria organizzazione.',
      img: './assets/img/servizioC_privacy.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- redazione e tenuta del registro delle attività di trattamento (RAT)
      <br>- corretta individuazione dei ruoli di titolare e responsabile del trattamento
      <br>- redazione delle informative per gli interessati dal trattamento
      <br>- individuazione, adozione e miglioramento delle misure tecniche organizzative
      <br>- formazione e sensibilizzazione del personale autorizzato al trattamento dei dati
      <br>- procedure di notifica di data breach 
      <br>- valutazione di impatto sulla protezione dei dati (DPIA)
      <br><br>
      L’Avv. Nicola Parisi è DPO (responsabile per la protezione dei dati personali) di numerosi soggetti pubblici e privati dislocati sull’intero territorio nazionale e operanti in vari ambiti (sanitario, commerciale, consulenziale, assistenziale, farmaceutico, di istruzione) ed è relatore in svariati corsi di formazione in materia privacy.
      `,
    },
    diritto_lavoro_previdenza : {
      prev:'privacy_gdpr',
      next:'diritto_famglia_persone',
      titolo: 'Diritto del lavoro e della previdenza sociale',
      description: 'Il lavoro e la previdenza sociale sono diritti di rango costituzionale il cui esercizio consente all’individuo di realizzarsi pienamente all’interno della collettività. In caso di violazione dei diritti dei lavoratori o di mancato riconoscimento delle prestazioni previdenziali e assistenziali, è necessario un intervento legale deciso e mirato.',
      img: './assets/img/servizioD_lavoro.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- procedure ex art. 445 bis cpc (ATPO)
      <br>- pensione di inabilità e assegno mensile (art. 12 e 13 L. 118/1971)
      <br>- indennità di accompagnamento
      <br>- indennità di frequenza
      <br>- legge 104/1992
      <br>- assegno ordinario e pensione di inabilità (art. 1 e 2 L. 222/1984)
      <br>- pensione anticipata
      <br>- emersione del lavoro nero anche tramite interpello dell’Ispettorato Nazionale del Lavoro
      <br>- differenze retributive
      <br>- mobbing
      <br>- mansioni superiori o demansionamento
      <br>- competenze di fine rapporto: TFR e rivalutazione TFR, richiesta di intervento al Fondo di Garanzia
      <br>- NASPI
      <br>- assegni familiari (ANF)
      <br><br>
      L’Avv. Roberto Positano, inoltre, presta consulenza in favore delle parti datoriali al fine della corretta applicazione dei contratti collettivi di categoria per evitare il perpetrarsi di abusi da parte dei lavoratori subordinati.
      `,
    },
    diritto_famglia_persone : {
      prev:'diritto_lavoro_previdenza',
      next:'diritto_amministrativo_pa',
      titolo: 'Diritto della famiglia e delle persone',
      description: 'Il fallimento di una relazione affettiva, i contrasti familiari, gli eventi imprevedibili della vita sono fatti che hanno ricadute giuridiche su chi ne è coinvolto. Per affrontare al meglio tali situazioni è necessario affidarsi a professionisti dotati non solo delle necessarie conoscenze tecniche ma anche di empatia e umanità. ',
      img: './assets/img/servizioE_famiglia.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- separazione giudiziale e consensuale
      <br>- divorzio contenzioso e congiunto
      <br>- scioglimento delle unioni civili
      <br>- modifica delle condizioni di separazione e divorzio
      <br>- responsabilità genitoriale
      <br>- redazione di dichiarazioni di successione
      <br>- accettazione e rinunce di eredità 
      <br>- impugnazione di testamenti
      <br>- divisioni ereditarie
      <br>- nomina di amministratore di sostegno
      <br>- autorizzazioni per minori ed incapaci
      <br><br>
      L’Avv. Annalisa Morelli nella gestione degli aspetti giuridici delle crisi familiari, soprattutto quando involgenti figli minori, su richiesta dei clienti si avvale anche del supporto e della collaborazione di psicologi e psicoterapeuti specializzati nel settore.
      `,
    },
    diritto_amministrativo_pa : {
      prev:'diritto_famglia_persone',
      next:'diritto_civile_commerciale',
      titolo: 'Diritto amministrativo e formazione PA',
      description: 'La conoscenza delle norme che regolano l’attività degli enti pubblici è necessaria per garantire l’efficiente funzionamento della PA e la tutela dei diritti dei cittadini e delle imprese. Gli operatori pubblici devono essere costantemente aggiornati sulle evoluzioni delle norme di settore oramai orientate verso la completa digitalizzazione mentre chi si relaziona con la PA talvolta ha la necessità di essere assistito da esperti conoscitori della materia.',
      img: './assets/img/servizioF_diritto_amministrativo.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- appalti pubblici
      <br>- accesso documentale, civico e generalizzato 
      <br>- urbanistica ed edilizia 
      <br>- pubblico impiego
      <br>- bandi e concorsi pubblici
      <br><br>
      L’Avv. Nicola Parisi patrocina enti pubblici, imprese e privati nel contenzioso amministrativo ed è relatore presso le PA per conto di enti di formazione accreditati, in materia di appalti pubblici, amministrazione trasparente, codice dell’amministrazione digitale (CAD), anticorruzione, codice di comportamento dei dipendenti pubblici, whistleblower.`,
    },
    diritto_civile_commerciale : {
      prev:'diritto_amministrativo_pa',
      next:'diritto_ecclesiastico_terzo_settore',
      titolo: 'Diritto civile e commerciale',
      description: 'I fatti della vita e le relazioni tra i soggetti economici sono regolati dalle norme del diritto civile e commerciale. Occorre pertanto individuare ed interpretare le regole giuridiche che disciplinano le varie situazioni e fornire gli strumenti necessari ad organizzare i rapporti tra i soggetti. In caso di contenzioso, bisogna impostare la strategia difensiva più corretta per ottenere la maggiore utilità per la parte assistita.',
      img: './assets/img/servizioG_diritto_commerciale.jpg',
      testo:  `<span class="font-22-500 color-primary">ActioAvvocati presta assistenza e consulenza in materia di:</span>
      <br>- diritto dei contratti (assistenza nella redazione dei testi negoziali, nazionali ed internazionali, nonché nella gestione dei contenziosi di carattere contrattuale)
      <br>- diritto societario e disciplina dell’impresa
      <br>- proprietà industriale ed intellettuale (ivi inclusa l’assistenza nella registrazione delle relative privative)
      <br>- responsabilità extracontrattuale
      <br>- proprietà, possesso e condominio
      <br>- diritto dei consumatori
      <br>- infortunistica stradale e danno biologico
      <br><br>
      L’Avv. Vito D’Alessandro ha maturato un’esperienza pluriennale nella redazione di contratti nazionali ed internazionali, lavorando anche alla creazione di joint-venture e di partnership per la diffusione di marchi e prodotti su mercati extraeuropei.
      <br><br>
      L’Avv. Nicola Parisi si occupa di registrazione di marchi europei e internazionali e presta consulenza al deposito di brevetti, applicazioni informatiche e software.`,
    },
    diritto_ecclesiastico_terzo_settore : {
      prev:'diritto_civile_commerciale',
      next:'diritto_scolastico',
      titolo: 'Diritto Ecclesiastico e del Terzo Settore',
      description: `Il D. Lgs. 117/2017 ha integralmente riformato il mondo dell’attività nel sociale e del volontariato dando vita ad una nuova ed articolata disciplina del “Terzo Settore”.
      Tale normativa si rivolge sia ai nuovi Enti che a quelli già esistenti (ONLUS, OdV, ecc.) per i quali si impongono molteplici attività di adeguamento.
      Al contempo per gli Enti Ecclesiastici si prospettano nuove e complesse sfide legate alla gestione diretta (o per il tramite di terzi) del patrimonio immobiliare, alla tutela dei beni storico-artistici di cui tali Enti sono custodi, alla definizione del contenzioso conseguente all’accertamento di imposte e tasse locali (IMU e TARI) ed al perseguimento delle finalità istituzionali nei settori della formazione, dell’assistenza sociale e socio-sanitaria e dell’accoglienza umanitaria.
      `,
      img: './assets/img/servizioH_terzosettore.jpg',
      testo:  `ActioAvvocati è da sempre vicino all’impegno sociale ed al mondo del volontariato.
      <br><br>
      L’avv. Vito D’Alessandro assiste molti Enti del Terzo Settore ed ha strutturato, dirigendolo, l’ufficio legale di diversi Enti Ecclesiastici di diritto pontificio che operano in Italia e all’estero.`,
    },
  };
  
  constructor(private route: ActivatedRoute, private router: Router) { 
    this.route.params.subscribe((params: ParamMap) => {
      this.idservice = params['idservice']; 
      this.item = this.service[this.idservice];
    });
  }

  ngOnInit() {
  }

  gotoPrev(){
    window.location.hash = 'home-top';
    this.router.navigate(['/service/'+this.item.prev]);
  }

  gotoNext(){
    window.location.hash = 'home-top';
    this.router.navigate(['/service/'+this.item.next]);
  }

  gotoLink(link){
    this.router.navigate([link]);
  }
}