import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  
  objectKey = Object.keys; 

  public menuIndex = 'news';
  public user;
  public news;

  constructor(public afAuth: AngularFireAuth, public db: AngularFireDatabase) { 
    this.getNews();
  }

  ngOnInit() {
    var self = this;
    this.afAuth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        self.user = user;
      }
    });
  }

  getNews(){
    this.db.object('news', ).valueChanges().subscribe( action =>{
      this.news = action;
      console.log(this.news);
    });
  }
}
