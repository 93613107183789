import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../service/auth/auth.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public menuIndex;
  public type;

  public loginForm: FormGroup;
  public registerForm: FormGroup;

  public spinnerLogin = false;
  public message;

  constructor(public formBuilder: FormBuilder, public authProvider: AuthService, private router: Router) { 
    this.type = 0;

    this.loginForm = formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required])),
    });

    this.registerForm = formBuilder.group({
      name: new FormControl('', Validators.compose([Validators.required])),
      surname: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  ngOnInit(): void {
  }

  accedi(){
    this.spinnerLogin=true;
    console.log(this.loginForm.value.email, this.loginForm.value.password);
    this.message=null;
    if (!this.loginForm.valid){
      this.spinnerLogin=false;
      this.message = 'Inserisci email e password.';
      console.log(this.loginForm.value);
    } else {
      this.authProvider.loginUser(this.loginForm.value.email, this.loginForm.value.password)
      .then( authData => {
        this.spinnerLogin=false;
        this.router.navigate(['/news']);
      }, error => {
        this.spinnerLogin=false;
        if(error=='Error: There is no user record corresponding to this identifier. The user may have been deleted.'){
          this.message="L'email e la password inserite non corrispondono.";
        }else{
          this.message = error;
        }
      }).catch(error => {
        this.spinnerLogin=false;
        console.log(error);
        if(error="TypeError: Cannot read property 'error' of null"){
          this.message="L'email e la password inserite non corrispondono.";
        }else{
          this.message=error;
        }
      });
    }
  }

  create(){
    this.message=null;
    this.spinnerLogin = true;
    if (!this.registerForm.valid || this.registerForm.value.privacy==false){
      this.spinnerLogin = false;
      console.log(this.registerForm.value);
      this.message='Inserisci nome e cognome';
    } else {
      this.authProvider.signupUser(this.registerForm.value.email, this.registerForm.value.password, this.registerForm.value.name, this.registerForm.value.surname)
      .then(() => {
        this.spinnerLogin = false;
        //autenticazione valida redirect
        this.router.navigate(['/news']);
      }, (error) => {
          this.spinnerLogin = false;
          if(error='Error: The email address is already in use by another account.'){
            this.message='Questo indirizzo email è gia presente.';
          }else{
            this.message = error;
          }
      });
    }
  }
}
