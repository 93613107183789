<section style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.md fxShow.gt-md>
      
    <!-- MENU LEFT -->
    <app-header [menuIndex]="menuIndex"></app-header>
    
    <!-- LAYOUT -->
    <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">
  
      <div id="home-top" fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; padding-top:60px;">
          <div fxFlex="60" style="padding-right:50px;">
            <div class="font-22-500" style="text-align: center; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
                {{item.titolo}}
            </div>
            <div class="font-22-500 color-primary" style="margin-top:20px;">
                {{item.description}}
            </div>
            <div [innerHtml]="item.testo" class="font-22-300 color-grigio" fxLayout="column" fxLayoutAlign="start start" style="margin-top:40px;">
   
            </div>
          </div>
          <div fxFlex="40">
              <div [style.backgroundImage]="'url('+ item.img +')'" style="width:100%; height: 30vw; background-size:cover; background-position:center;">

              </div>
          </div>
      </div>

      

      <div fxLayout="row" fxLayoutAlign="center center" style="margin-top:80px">
        <button (click)="gotoPrev()" class="button-border" style="margin-right:10px">Settore precedente</button>
        <button (click)="gotoNext()" class="button-primary" style="margin-left: 10px;">Settore successivo</button>
      </div>
     
      <!--
      <div fxLayout="row" fxLayoutAlign="start center" style="padding-left:50px; padding-right:50px; margin-top:60px;">
        <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
        <div fxFlex="10" style="text-align: center;" class="font-26-900 color-primary">
          Altri Servizi
        </div>
        <div fxFlex="45" style="width: 100%; height: 1px; background-color: #003664;"></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" style="padding-left: 50px; padding-right:50px; margin-top:50px; margin-bottom:50px;">
        <div *ngIf="idservice!='diritto-scolastico'" fxFlex="25" style="padding-left: 25px; padding-right: 25px;">
            <img src="./assets/img/service/servizi_01_dirittoscolastico.jpg" width="100%">

            <div class="font-22-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Diritto scolastico
            </div>
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
        </div>
        <div *ngIf="idservice!='formazione-pa'" fxFlex="25" style="padding-left: 25px; padding-right: 25px;">
            <img src="./assets/img/service/servizi_02_pa.jpg" width="100%">

            <div class="font-22-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Formazione P.A.
            </div>
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
        </div>
        <div *ngIf="idservice!='privacy-gdpr'" fxFlex="25" style="padding-left: 25px; padding-right: 25px;">
            <img src="./assets/img/service/servizi_03_privacy.jpg" width="100%">

            <div class="font-22-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Privacy e GDPR
            </div>
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
        </div>
        <div *ngIf="idservice!='famiglie'" fxFlex="25" style="padding-left: 25px; padding-right: 25px;">
            <img src="./assets/img/service/servizi_04_famiglie.jpg" width="100%">

            <div class="font-22-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Famiglie
            </div>
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
        </div>
        <div *ngIf="idservice!='previdenza'" fxFlex="25" style="padding-left: 25px; padding-right: 25px;">
            <img src="./assets/img/service/servizi_05_previdenze.jpg" width="100%">

            <div class="font-22-500" style="margin-top:10px; color:white; background-color: #003664; padding-left: 20px; padding-right: 20px; padding-top:5px; padding-bottom:5px;">
              Previdenza
            </div>
            <div class="font-18-300 color-grigio" style="margin-top:10px;">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus malesuada pharetra tempor. In neque urna, imperdiet in augue ut, hendrerit venenatis dui. In laoreet, leo sit amet dapibus ultricies, sapien nibh ullamcorper lectus, ut eleifend urna arcu sit amet urna. Quisque id euismod turpis
            </div>
            <div style="margin-top:20px;">
              <button class="button-border">Approfondisci</button>
            </div>
        </div>
      </div>
      -->
  
      <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:50px; padding-right:50px; margin-top:80px;">
        <div class="font-22-300 color-white">
          Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
        </div>
        <button (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
      </div>  


      <app-footer></app-footer>
    </div>
</section>


<section style="width:100%; background-color: white; overflow-y: hidden; height: 100vh;" fxHide fxShow.lt-md>
      
  <!-- MENU LEFT -->
  <app-header [menuIndex]="menuIndex"></app-header>
  
  <!-- LAYOUT -->
  <div class="scroll-bar" style="height: calc( 100% - 80px); overflow-y: scroll; -webkit-overflow-scrolling: touch; width: 100%;">

    <div id="home-top" fxLayout="column" fxLayoutAlign="start start" style="padding-left: 15px; padding-right:15px; padding-top:40px;">
        <div class="font-22-500" style="width: 100%; text-align: center; color:white; background-color: #003664; padding-top:5px; padding-bottom:5px;">
            {{item.titolo}}
        </div>
        <div class="font-22-500 color-primary" style="margin-top:20px;">
            {{item.description}}
        </div>
        <div [style.backgroundImage]="'url('+ item.img +')'" style="width:100%; margin-top: 20px; height: 60vw; background-size:cover; background-position:center;">

        </div>
        <div [innerHtml]="item.testo" class="font-22-300 color-grigio" fxLayout="column" fxLayoutAlign="start start" style="margin-top:40px;">

        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top:40px; padding-left: 15px; padding-right: 15px;">
      <button (click)="gotoPrev()" class="button-border" style="margin-right:10px">Settore precedente</button>
      <button (click)="gotoNext()" class="button-primary" style="margin-left: 10px;">Settore successivo</button>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="padding-top: 20px; padding-bottom: 20px; background-color: #003664; padding-left:15px; padding-right:15px; margin-top:40px;">
      <div class="font-22-300 color-white" style="text-align: center;">
        Per qualsiasi informazioni <span style="font-weight: 500;">scrivici un messaggio</span> attraverso il form di contatto
      </div>
      <button style="margin-top: 20px;" (click)="gotoLink('/contact')" class="button-white">Contattaci</button>
    </div>  

    <app-footer></app-footer>
  </div>
</section>