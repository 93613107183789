import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService } from '../../service/auth/auth.service';
import { AngularFireAuth } from '@angular/fire/auth'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input('menuIndex')
  menuIndex;

  public menu=false;
  public about = false;
  public service = false;

  public userData;
  public settoreMenu = false;
  public chisiamoMenu = false;
  
  public user;

  constructor(private router: Router, public authProvider: AuthService, public afAuth: AngularFireAuth) { 

  }

  ngOnInit() {
    var self = this;
    this.afAuth.onAuthStateChanged( user => {
      if (user){
        console.log(user);
        self.user = user;
      }
    });
  }

  openMenu(){
    if(this.menu){
      this.menu=false;
    }else{
      this.menu=true;
    }
  }

  openAbout(){
    this.service=false;
    if(this.about){
      this.about=false;
    }else{
      this.about=true;
    }
  }

  openService(){
    this.about=false;
    if(this.service){
      this.service=false;
    }else{
      this.service=true;
    }
  }

  changeSettore(){
    if(this.settoreMenu){
      this.settoreMenu = false;
    }else{
      this.settoreMenu = true;
    }
  }

  changeChisiamo(){
    if(this.chisiamoMenu){
      this.chisiamoMenu = false;
    }else{
      this.chisiamoMenu = true;
    }
  }

  gotoPage(link){
    this.router.navigate([link]);
    this.menu = false;
  }

  gotoFacebook(){
    window.open('https://www.facebook.com/actioavvocati/');
  }

  gotoLinkedin(){
    window.open('https://www.linkedin.com/company/actioavvocati/');
  }

  menuUser = false;
  logout(){
    this.menuUser=false;
    this.authProvider.logoutUser();
    this.router.navigate(['/home']);
  }

  openMenuUser(){
    if(this.menuUser){
      this.menuUser = false;
    }else{
      this.menuUser = true;
    }
  }
}
