import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public http: HttpClient, public afAuth: AngularFireAuth, private db: AngularFireDatabase) { 

  }

  loginUser(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logoutUser() {
    return this.afAuth.signOut();
  }

  signupUser(email: string, password: string, name: string, surname: string): Promise<any> {
    var self = this;
    return this.afAuth
    .createUserWithEmailAndPassword(email, password)
    .then( newUser => {
      console.log('newUser', newUser.user.uid);

      var utente = {
        email: email,
        name: name,
        surname: surname,
      }

      self.db.object('users/'+newUser.user.uid).set(utente).then(function(snapshot) {
        console.log(snapshot);
      });

      /*
      var db = firebase.firestore();
      db.collection("users").doc(newUser.user.uid).set({
          uid: newUser.user.uid,
          name: name,
          surname: surname,  
          email : email,
          lang:'it',
      })
      .then(function(doc) {
        console.log("Utente registrato correttamente.");
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
      */
    });
  }
}
