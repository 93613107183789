<section fxLayout="row" fxLayoutAlign="space-between center" class="header" fxHide fxShow.md fxShow.gt-md>

  <div routerLink="/home" style="cursor: pointer; outline:none; padding-left: 50px;">
    <img src="./assets/img/logo/logo_actio_white.svg" width="200px">
  </div>

  <div fxLayout="row" fxLayoutAlign="end center" style="padding-right:50px;">
    <div>
      <button routerLink="/study" class="btn-menu">Lo studio</button>
    </div>

    <div style="position: relative;">
      <div>
          <button (click)="openAbout()" class="btn-menu">Chi siamo<i *ngIf="!about" style="margin-left: 6px;" class="fas fa-angle-down"></i><i *ngIf="about" style="margin-left: 6px;" class="fas fa-angle-up"></i></button>
      </div>

      <div *ngIf="about" style="background-color: #F7F7F7; color: #003664; width:250px; height:204px; position:absolute; top:54px; right:0px; z-index: 999;">
        <div routerLink="/about/avv_nicola_parisi" (click)="about=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Avv. Nicola Parisi
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/about/avv_vito_d_alessandro" (click)="about=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Avv. Vito D'Alessandro
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/about/avv_annalisa_morelli" (click)="about=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Avv. Annalisa Morelli
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/about/avv_roberto_positano" (click)="about=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Avv. Roberto Positano
        </div>
      </div>
    </div>

    <div style="position: relative;">
      <div>
          <button (click)="openService()" class="btn-menu">Settori<i *ngIf="!service" style="margin-left: 6px;" class="fas fa-angle-down"></i><i *ngIf="service" style="margin-left: 6px;" class="fas fa-angle-up"></i></button>
      </div>

      <div *ngIf="service" style="background-color: #F7F7F7; color: #003664; width:250px; height:408px; position:absolute; top:54px; right:0px; z-index: 999;">
        <div routerLink="/service/diritto_scolastico" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto Scolastico
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/compliance_aziendale" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Compliance aziendale
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/privacy_gdpr" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Privacy e GDPR
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/diritto_lavoro_previdenza" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto del lavoro e della previdenza sociale
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/diritto_famglia_persone" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto di famgilia e delle persone
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/diritto_amministrativo_pa" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto amministrativo e formazione PA
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/diritto_civile_commerciale" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto civile e commerciale
        </div>
        <div style="width:100%; background-color:white; height: 1px;"></div>
        <div routerLink="/service/diritto_ecclesiastico_terzo_settore" (click)="service=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
          Diritto Ecclesiastico e del Terzo settore
        </div>
      </div>
    </div>
    <!--
    <div>
      <button routerLink="/news" class="btn-menu">News</button>
    </div>
    -->
    <div>
        <button routerLink="/contact" class="btn-menu">Contatti</button>
    </div>  
    
    <div *ngIf="user">
      <div>
        <div style="cursor: pointer;" (click)="openMenuUser()">
          <span style="color:#b4b4b4; font-size: 14px; text-transform: lowercase;">{{user.email}}<i *ngIf="!menuUser" style="margin-left: 6px;" class="fas fa-angle-down"></i><i *ngIf="menuUser" style="margin-left: 6px;" class="fas fa-angle-up"></i></span>
        </div>

        <div *ngIf="menuUser" style="background-color: #F7F7F7; color: #003664; width:250px; height:108px; position:absolute; top:54px; right:46px; z-index: 999;">
          <div routerLink="/news-create" (click)="menuUser=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
            Crea una news
          </div>
          <div style="width:100%; background-color:white; height: 1px;"></div>
          <div (click)="logout()" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 25px; padding-right:25px;" class="font-16-400 color-primary menu-hover">
            Esci
          </div>
        </div>
      </div>  
    </div>

  </div>
</section>



<section fxLayout="row" fxLayoutAlign="space-between center" class="header"  fxHide fxShow.lt-md>

  <div style="padding-left:15px; width:30px; cursor: pointer;" (click)="openMenu();">
      <i *ngIf="!menu" style="color:white; font-size:26px;" class="fas fa-bars"></i>
      <i *ngIf="menu" style="color:white; font-size:26px;" class="fas fa-times"></i>
  </div>
  <div routerLink="/home" style="cursor: pointer; outline:none;">
    <img src="./assets/img/logo/logo_actio_white.svg" width="200px">
  </div>
  <div style="width:30px;">

  </div>

</section>


<section fxHide fxShow.lt-md fxLayout="column" fxLayoutAlign="start start" *ngIf="menu" class="shadow-menu" style="position:absolute; overflow: hidden; height: calc(100vh - 80px); background-color:#003664; width:100%; z-index:9999;">
  
  <div style="overflow-y: scroll; height: 100%; width:100%;" class="scroll-bar">
      <div style="width:100%;">
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
        <div>
          <div class="btn-menu-mobile" (click)="gotoPage('home')">
            Home
          </div>
        </div>
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
        <div>
            <div class="btn-menu-mobile" (click)="gotoPage('study')">
              Lo studio
            </div>
        </div>
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="btn-menu-mobile" (click)="changeChisiamo()">
              <div>Chi siamo</div>

              <div *ngIf="!chisiamoMenu" style="cursor: pointer; color:white">
                <i class="fas fa-angle-down"></i>
              </div>
              <div *ngIf="chisiamoMenu" style="cursor: pointer; color:#003664">
                <i class="fas fa-angle-up"></i>
              </div>
            </div>
            <div *ngIf="chisiamoMenu">
              <div routerLink="/about/avv_nicola_parisi" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Avv. Nicola Parisi
              </div>
              <div routerLink="/about/avv_vito_d_alessandro" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Avv. Vito D'Alessandro
              </div>
              <div routerLink="/about/avv_annalisa_morelli" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Avv. Annalisa Morelli
              </div>
              <div routerLink="/about/avv_roberto_positano" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Avv. Roberto Positano
              </div>
            </div>
        </div>
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="btn-menu-mobile" (click)="changeSettore()">
              <div>Settori</div>

              <div *ngIf="!settoreMenu" style="cursor: pointer; color:white">
                <i class="fas fa-angle-down"></i>
              </div>
              <div *ngIf="settoreMenu" style="cursor: pointer; color:#003664">
                <i class="fas fa-angle-up"></i>
              </div>
            </div>
            <div *ngIf="settoreMenu">
              <div routerLink="/service/diritto_scolastico" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto Scolastico
              </div>
              <div routerLink="/service/compliance_aziendale" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Compliance aziendale
              </div>
              <div routerLink="/service/privacy_gdpr" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Privacy e GDPR
              </div>
              <div routerLink="/service/diritto_lavoro_previdenza" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto del lavoro e della previdenza sociale
              </div>
              <div routerLink="/service/diritto_famglia_persone" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto di famgilia e delle persone
              </div>
              <div routerLink="/service/diritto_amministrativo_pa" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto amministrativo e formazione PA
              </div>
              <div routerLink="/service/diritto_civile_commerciale" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto civile e commerciale
              </div>
              <div routerLink="/service/diritto_ecclesiastico_terzo_settore" (click)="menu=false" fxLayout="row" fxLayoutAlign="start center" style="cursor: pointer; height: 50px; padding-left: 65px; padding-right:20px; color:#cacbcc;" class="font-16-400">
                Diritto Ecclesiastico e del Terzo settore
              </div>
            </div>
        </div>
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
        <div>
          <div class="btn-menu-mobile" (click)="gotoPage('contact')">
            Contatti
          </div>
        </div>
        <div style="height: 1px; border-top:1px solid #004179; width:100%;"></div>
      </div>

      <div style="padding-left: 25px; margin-top:25px">
          <i (click)="gotoFacebook()" style="cursor: pointer; color:white; font-size: 25px; margin-right: 10px;" class="fab fa-facebook-square"></i>
          <i (click)="gotoLinkedin()" style="cursor: pointer; color:white; font-size: 25px;" class="fab fa-linkedin-in"></i>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" style="padding-left: 25px; margin-top:25px;">
        <div routerLink="/cookie-policy" style="cursor: pointer; color:#cfcfcf; font-weight: 400;">
          Privacy - Cookie
        </div>
      </div>

  </div>
</section>